import { useState, useContext, useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import CommonOnboardingBackground from "./CommonOnboardingBackground";
import OnboardingWelcome from "./OnboardingWelcome";
import OnboardingWorkSpaceList from "./OnBoardingWorkspaceList";
import CommonOnboardingPrgBarTitle from "./CommonOnboardingPrgBarTitle";
import OnBoardingDeviceManufacturer from "./OnBoardingDeviceManufacturer";
// import OnBoardingDeviceManufacturer from "./OnBoardingDeviceManufacturer";
import OnBoardingDataSourcePipelineSetup from "./OnBoardingDataSourcePipelineSetup";
import OnboardingFooter from "./CommonOnboardingFooter";
import ProductLisiting from "./ProductLisiting";
import { Col, Row } from "reactstrap";
import ConfigureDevice from "./ConfigureDevice";
import axiosAdapter from "../../utils";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { env } from "../../env";
import SearchBox from "../../components/onBoardingComponents/_searchBox";
import { useTour } from "@reactour/tour";

const OnboardingMainPage = (props) => {
  const { setIsOpen, setSteps } = useTour();
  const { showMessage } = useContext(SnackbarContext);
  const history = useHistory();
  let newConfigId;

  const [onBoardingState, setOnBoardingState] = useState(2);
  const [selectedWorkspaceId, setselectedWorkspaceId] = useState(null);
  const [selectedSource, setSelectedSource] = useState("");
  const [isDeviceSelected, setIsDeviceSelected] = useState(false);
  const [isSelectedCardName, setIsSelectedCardName] = useState(null);
  const [isSelectedManufactName, setIsSelectedManufactName] = useState(null);
  const [reFetchWorkSpace, setReFetchWorkSpace] = useState(false);

  const [isSelectedDevicePort, setisSelectedDevicePort] = useState(null);
  const [isSelectedNetworkType, setIsSelectedNetworkType] = useState(null);
  const [isSelectedStatus, setIsSelectedStatus] = useState(null);

  const [configs, setConfigs] = useState([]);
  const [inputValues, setInputValues] = useState([]);

  useEffect(() => {
    if (onBoardingState == 6) {
      fetchConfigs();
    }
  }, [onBoardingState == 6]);

  const fetchConfigs = async () => {
    try {
      let stringifiedData = JSON.stringify({
        connectorName: "MQTT",
      });

      let fetchConfigResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getConnectorConfigs",
        stringifiedData
      );

      setConfigs(fetchConfigResponse.data.data.config);
    } catch (err) {
      console.log("err:", err);
    }
  };

  const handleConfigFieldsInputChange = (e, index) => {
    const { value } = e.target;

    // Update the input values array with the new value at the specified index
    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = value;
    setInputValues(updatedInputValues);
  };

  const handleConfigCheck = () => {
    const isFormValid = configs.every((config, index) => {
      const { type, label, isRequired } = config;
      const value = inputValues[index] || "";

      if (!isRequired) {
        return true; // Skip checking for non-required fields
      }

      if (type === "number") {
        const numericValue = Number(value);
        if (!isNaN(numericValue) && numericValue.toString().length >= 3) {
          return true;
        }
      } else if (type === "text") {
        if (typeof value === "string" && value.length >= 3) {
          return true;
        }
      } else {
        return true; // Skip validation for other types
      }

      showMessage(
        `${label} field must be 3 ${
          type == "text" ? "alphabet" : "number"
        } characters.`
      );
      return false;
    });

    if (isFormValid) {
      return true;
    } else {
      return false;
    }
  };

  const combineConfigAndInputValues = () => {
    const configObject = {};

    configs.forEach((config, index) => {
      const { name } = config;
      configObject[name] = inputValues[index] || "";
    });

    return configObject;
  };

  const setConnectorConfigs = async () => {
    let combineResponse = combineConfigAndInputValues();

    try {
      let stringifiedData = JSON.stringify({
        connectorName: isSelectedCardName,
        config: combineResponse,
      });

      let setConnectorConfigResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/setConnectorConfigs",
        stringifiedData
      );

      if (setConnectorConfigResponse.data.status == "OK") {
        newConfigId = setConnectorConfigResponse.data.data.id;
        showMessage("Connector Configured Successfully", "success");
        return true;
      }

      setInputValues([]);
      return false;
    } catch (err) {
      console.log("err:", err);
      setInputValues([]);
      return false;
    }
  };

  useEffect(() => {
    //This useEffect checks if WorkSpace Layout was called from Navbar Workspace dropdown
    // We will revceive onBoardingState and selectedWorkspaceId from history.push
    const recievedOnBoardingState = props.location?.onBoardingState;
    const recievedWorkSpaceId = props.location?.selectedWorkspaceId;

    if (recievedOnBoardingState != undefined && onBoardingState == 2) {
      setOnBoardingState(recievedOnBoardingState);
      setselectedWorkspaceId(recievedWorkSpaceId);
    }
  });

  useEffect(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    // Setting onBoardingState to 2, when browser back button is clicked
    window.addEventListener("popstate", function (event) {
      setOnBoardingState(2);
    });

    //Reloading Page to clear states, when clicked on Condense Logo
    const recievedNavBarOnBoardingState = props.location?.navBarOnBoardingState;

    if (recievedNavBarOnBoardingState != undefined) {
      window.location.reload();
    }
  });

  const handleOnBoardingPage = (pageState, backButtonPageName) => {
    // console.log("pageState:", pageState);

    if (pageState == 3) {
      setIsDeviceSelected(false);
      setOnBoardingState(pageState);
    } else if (pageState == 2 && backButtonPageName == "sourcePageBackButton") {
      window.location.reload();
    } else if (pageState == 5) {
      history.push("/connectors");
    } else {
      setOnBoardingState(pageState);
    }
  };

  const handlePushToLayout = (selectedWorkspace) => {
    history.push({
      pathname: "connectorsPlayground",
      selectedWorkspace: selectedWorkspace,
    });
  };

  const handleRouteConnectorsPage = async (newWorkSpaceID) => {
    if (newWorkSpaceID) {
      let stringifyData = {
        id: newWorkSpaceID,
        layout: {
          node: [
            {
              id: "1",
              type: "custom",
              data: {
                label: "input",
                title: "Input Data Source",
                sourceName: "Telematics Device",
                port: Math.floor(1024 + Math.random() * (65535 - 1024 + 1)),
                deviceModel: isSelectedCardName,
                mnufactName: isSelectedManufactName,
                status: "Running",
                isConnector: false,
                configId: newConfigId,
              },
            },
          ],
          edges: [
            {
              id: "reactflow__edge-1-2",
              source: "1",
              sourceHandle: null,
              targetHandle: null,
            },
          ],
        },
      };

      let stringifyDataFeatureList = {
        clientId: env.REACT_APP_CLIENT_ID,
        featureKey: "TELEMATICS_DEVICE",
        workspaceId: newWorkSpaceID,
      };
      let featureDeployment = axiosAdapter(
        "POST",
        env.REACT_APP_URL + "admin/setDeploymentsForFeature",
        stringifyDataFeatureList
      );

      let setWorkspaceList = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/setWorkSpace",
        stringifyData
      );
      console.log("setWorkspaceList", setWorkspaceList);
      if (setWorkspaceList && setWorkspaceList.data.status == "OK") {
        let selectedWorkspace = newWorkSpaceID;
        history.push({
          pathname: "connectorsPlayground",
          selectedWorkspace: selectedWorkspace,
        });
      } else {
        showMessage("No Workspace ID");
      }
    }
  };

  const handleRefetchWorkSpace = () => {
    setReFetchWorkSpace(!reFetchWorkSpace);
  };

  const handlingWorkSpace = (workSpaceId, layout) => {
    setselectedWorkspaceId(workSpaceId);
    if (layout === null) {
      // handleRouteConnectorsPage(workSpaceId);
      setOnBoardingState(3);
    } else if (layout !== null) handlePushToLayout(workSpaceId);
  };

  const handleSourceCardSelection = (selectedSourceName) => {
    if (selectedSource == selectedSourceName) {
      setSelectedSource("");
    } else {
      setSelectedSource(selectedSourceName);
    }
  };

  const handleDeviceCardSelection = (selectionState) => {
    setIsDeviceSelected(selectionState);
  };

  const handleRouteToConnectors = () => {
    if (selectedSource != "") {
      history.push({
        pathname: "connectors",
        selectedSource: selectedSource,
      });
    } else {
      showMessage("Please select a Source", "success");
    }
  };

  const handleDeviceSelected = (
    selectedCardName,
    selectedManufactName,
    selectedDevicePort,
    selectedNetworkType,
    selectedStatus
  ) => {
    setIsSelectedCardName(selectedCardName);
    setIsSelectedManufactName(selectedManufactName);
    setisSelectedDevicePort(selectedDevicePort);
    setIsSelectedNetworkType(selectedNetworkType);
    setIsSelectedStatus(selectedStatus);
  };

  switch (onBoardingState) {
    case 1:
      return (
        <CommonOnboardingBackground>
          <OnboardingWelcome
            onClickConnectSource={() => handleOnBoardingPage(2)}
          />
        </CommonOnboardingBackground>
      );

    case 2:
      return (
        <CommonOnboardingBackground>
          <Col xs={12} className="onBoardingContentStyle">
            <CommonOnboardingPrgBarTitle
              title={"Your Workspaces"}
              showButton={true}
              handleRefetchWorkSpace={handleRefetchWorkSpace}
            />
            <OnboardingWorkSpaceList
              handlingWorkSpace={handlingWorkSpace}
              reFetchWorkSpace={reFetchWorkSpace}
            />
          </Col>
          <OnboardingFooter
            showNextAndBackButton={false}
            onClickBack={() => handleOnBoardingPage(1)}
            onClickNext={() => handleOnBoardingPage(3)}
          />
        </CommonOnboardingBackground>
      );

    case 3:
      return (
        <CommonOnboardingBackground>
          <Col xs={12} className="onBoardingContentStyle">
            <CommonOnboardingPrgBarTitle title={"Your Workspaces"} />
            <OnBoardingDataSourcePipelineSetup
              handleSourceCardSelection={handleSourceCardSelection}
              selectedSourceName={selectedSource}
            />
          </Col>
          <Col xs={12}>
            <OnboardingFooter
              showNextAndBackButton={true}
              onClickBack={() =>
                handleOnBoardingPage(2, "sourcePageBackButton")
              }
              // onClickNext={() => handleOnBoardingPage(5)}
              onClickNext={() => handleRouteToConnectors()}
            />
          </Col>
        </CommonOnboardingBackground>
      );
    case 4:
      return (
        <CommonOnboardingBackground>
          <Row>
            <Col xs={12} className="onBoardingContentStyle">
              <CommonOnboardingPrgBarTitle
                title={"Setup your first Pipeline"}
              />
              <OnBoardingDataSourcePipelineSetup />
            </Col>
          </Row>
          <OnboardingFooter />
        </CommonOnboardingBackground>
      );
    // case 5:
    //   return (
    //     <CommonOnboardingBackground>
    //       <CommonOnboardingPrgBarTitle title={"Setup your first Pipeline"} />
    //       <OnBoardingDeviceManufacturer />
    //     </CommonOnboardingBackground>
    //   );
    case 5:
      return (
        <CommonOnboardingBackground>
          <Col xs={12} className="onBoardingContentStyle">
            <CommonOnboardingPrgBarTitle title={"Setup your first Pipeline"} />
            <ProductLisiting
              handleDeviceCardSelection={handleDeviceCardSelection}
              handleDeviceSelected={handleDeviceSelected}
            />
          </Col>
          <OnboardingFooter
            isDeviceSelected={isDeviceSelected}
            showNextAndBackButton={true}
            onClickBack={() => handleOnBoardingPage(3)}
            onClickNext={() => handleOnBoardingPage(6)}
            buttonTxt="NEXT"
          />
        </CommonOnboardingBackground>
      );

    case 6:
      return (
        <CommonOnboardingBackground>
          <Col xs={12} className="onBoardingContentStyle">
            <CommonOnboardingPrgBarTitle
              title={"Setup your First Data Source"}
            />
            <ConfigureDevice
              deviceName={isSelectedCardName}
              configs={configs}
              handleConfigFieldsInputChange={handleConfigFieldsInputChange}
              inputValues={inputValues}
            />
          </Col>
          <OnboardingFooter
            showNextAndBackButton={true}
            onClickBack={() => handleOnBoardingPage(5)}
            onClickNext={() => handleRouteConnectorsPage(selectedWorkspaceId)}
            buttonTxt="DONE"
          />
        </CommonOnboardingBackground>
      );

    // case 7:
    //   return (
    //     <CommonOnboardingBackground>
    //       <StyledTopSnackBar text={"Please login, There is an error"}/>
    //       {/* <OnBoardingAddedConnectors /> */}
    //     </CommonOnboardingBackground>
    //   );

    default:
      return (
        <CommonOnboardingBackground>
          <OnboardingWelcome
            onClickConnectSource={() => handleOnBoardingPage(1)}
          />
        </CommonOnboardingBackground>
      );
  }
};

export default withRouter(OnboardingMainPage);
