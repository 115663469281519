import { useState, createContext, useEffect, useContext } from "react";
import WorkSpaceConnectorsCard from "./WorkSpaceConnectorsCard";
import ConnectorFilterModal from "./ConnectorFilterModal";
import DemoIcon from "../../../src/assets/images/demo.jpeg";
import SettingsIcon from "../../../src/assets/images/Settings.svg";
import expandIcon from "../../assets/images/expandDropDown.png";
import ConnectorsConfigurationPage from "./ConnectorsConfigurationPage";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { useHistory, withRouter } from "react-router-dom";
import Stepper from "./Stepper";
import { Spinner } from "reactstrap";
import { useTour } from "@reactour/tour";
import { connectorsWalkthrough } from "../../steps";
//Context created to manage all States and Functions
export const ConnectorsContext = createContext("");

const WorkSpaceConnectorsMainPage = (props) => {
  const { setIsOpen, currentStep, setSteps, steps, isOpen } = useTour();

  const { selectedConnector } = props;
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);

  let newConfigId;

  const [searchValue, setSearchValue] = useState("");
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [connectorsArrState, setConnectorsArrState] = useState([]);
  const [initialConnectorsArr, setInitialConnectorsArr] = useState([]);

  const [tempCategoryArray, setTempCategoryArray] = useState([]);
  const [tempSubCategoryArray, setTempSubCategoryArray] = useState([]);

  const [isAddPipelineClicked, setIsAddPipelineClicked] = useState(false);
  const [selectedConnectorType, setSelectedConnectorType] = useState("");
  const [selectedConnectorName, setSelectedConnectorName] = useState("");
  const [configs, setConfigs] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const [isConfigApisBeingCalled, setIsConfigApisBeingCalled] = useState(false);
  const [stepperCount, setStepperCount] = useState(2);
  const [disableConfigInputs, setDisableConfigInputs] = useState(false);
  const [workspaceLayout, setWorkspaceLayout] = useState({});
  const [currentConfigId, setCurrentConfigId] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [selectedSourceOfConnectors, setSelectedSourceOfConnectors] = useState(
    props.location?.selectedSource
  );
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(
    localStorage.getItem("selectedWorkSpaceId")
  );

  //Transform Related States
  const [transformConnectorName, setTransformConnectorName] = useState("");
  const [transformConfigParams, setTransformConfigParams] = useState([]);

  const getAllStreamConnectors = async () => {
    setIsloading(true);
    // FIXME: Need to add the transform in the backend;
    let transformConnectorSample = [
      {
        id: "09c3e522-4855-45c4-b65a-e2c8c1d92958",
        name: "Threshold",
        type: "TRANSFORMS",
        description: "Threshold Transform Connector",
        code: "HTTP",
        connectionType: '{"category":"transform","subCategory":["All"]}',
        icon_link:
          "https://dev-storage.aquilatrack.com/connector-images/Amazon-Kinesis.webp?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio_zeliot_storage%2F20230817%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230817T081329Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=3e40c8ad216a3e8d934c05ec5560d7c14f866bb9f10ccd2c0bdffa87d638603e",
      },
    ];
    let listStreamConnectors = await fetchStreamConnectors();
    let filteredStreamConnectors = listStreamConnectors.filter(
      (eachConnector) => eachConnector.type == "INPUT"
    );

    let listStoreConnectors = await fetchStoreConnectors();
    let filteredStoreConnectors = listStoreConnectors.filter(
      (eachConnector) => eachConnector.type == "INPUT"
    );

    let listDevices = await fetchDevices();
    let listAllConnectors;

    if (selectedSourceOfConnectors == "Telematic Devices") {
      listAllConnectors = [...listDevices];
    } else if (selectedSourceOfConnectors == "Databases") {
      listAllConnectors = [...filteredStoreConnectors];
    } else if (selectedSourceOfConnectors == "Connectors") {
      listAllConnectors = [...filteredStreamConnectors];
    } else {
      listAllConnectors = [
        ...transformConnectorSample,
        ...listStreamConnectors,
        ...listStoreConnectors,
        ...listDevices,
      ];
    }
    setConnectorsArrState(listAllConnectors);
    setIsloading(false);
    setInitialConnectorsArr(listAllConnectors);
  };

  const fetchDevices = async () => {
    let stringifiedData = JSON.stringify({
      manufacturerId: 31,
    });
    let fetchedDevices = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "admin/getDevicesForManufacturer",
      stringifiedData
    );
    return fetchedDevices.data.data;
  };

  async function fetchStreamConnectors() {
    let listStreamConnectors = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "admin/listAllMasterStreamConnectors"
    );
    console.log("listStreamConnectors", listStreamConnectors.data);
    if (listStreamConnectors && listStreamConnectors.data) {
      return listStreamConnectors.data;
    }
  }

  async function fetchStoreConnectors() {
    let listStoreConnectors = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "admin/listAllMasterStoreConnectors"
    );
    console.log("listStreamConnectors", listStoreConnectors.data);
    if (listStoreConnectors && listStoreConnectors.data) {
      return listStoreConnectors.data;
    }
  }

  useEffect(() => {
    getAllStreamConnectors();
    setSteps(connectorsWalkthrough);
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (selectedConnectorName != "") {
      fetchConfigs();
    }
  }, [selectedConnectorName]);

  const fetchConfigs = async () => {
    try {
      let stringifiedData = JSON.stringify({
        connectorName: selectedConnectorName,
        type: selectedConnectorType,
      });

      let fetchConfigResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getConnectorConfigs",
        stringifiedData
      );

      setConfigs(fetchConfigResponse.data.data.config);
    } catch (err) {
      console.log("err:", err);
    }
  };

  useEffect(() => {
    handleSearchTextChange(searchValue);
  }, [searchValue]);

  const handleSearchTextChange = (text) => {
    setSearchValue(text);

    let filteredConnectors = initialConnectorsArr.filter((connector) => {
      if (connector.name) {
        return connector?.name?.toLowerCase().includes(text.toLowerCase());
      } else if (connector.device_name) {
        return connector?.device_name
          ?.toLowerCase()
          .includes(text.toLowerCase());
      }
    });

    setConnectorsArrState(
      text === "" ? initialConnectorsArr : filteredConnectors
    );
  };

  const handleConfigFieldsInputChange = (e, index) => {
    const { value } = e.target;

    // Update the input values array with the new value at the specified index
    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = value;
    setInputValues(updatedInputValues);
  };

  //Config input fields validations
  const handleConfigCheck = () => {
    const isFormValid = configs.every((config, index) => {
      const { type, label, isRequired } = config;
      const value = inputValues[index] || "";

      if (!isRequired) {
        return true; // Skip checking for non-required fields
      }

      if (type === "number") {
        const numericValue = Number(value);
        if (!isNaN(numericValue) && numericValue.toString().length >= 3) {
          return true;
        }
      } else if (type === "text") {
        if (typeof value === "string" && value.length >= 3) {
          return true;
        }
      } else {
        return true; // Skip validation for other types
      }

      showMessage(
        `${label} field must be 3 ${
          type == "text" ? "alphabet" : "number"
        } characters.`
      );
      return false;
    });

    if (isFormValid) {
      return true;
    }
  };

  const combineConfigAndInputValues = () => {
    const configObject = {};

    configs.forEach((config, index) => {
      const { name } = config;
      configObject[name] = inputValues[index] || "";
    });

    return configObject;
  };

  const setConnectorConfigs = async () => {
    setIsConfigApisBeingCalled(true);
    let combineResponse = combineConfigAndInputValues();

    if (selectedConnectorName && combineResponse && selectedConnectorType) {
      try {
        let stringifiedData = JSON.stringify({
          connectorName:
            selectedConnectorType == "TRANSFORMS"
              ? transformConnectorName
              : selectedConnectorName,
          config:
            selectedConnectorType == "TRANSFORMS"
              ? transformConfigParams
              : combineResponse,
          type: selectedConnectorType,
        });

        let setConnectorConfigResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/setConnectorConfigs",
          stringifiedData
        );

        if (setConnectorConfigResponse.data.status == "OK") {
          console.log(
            "setConnectorConfigResponse.data.data.id....",
            setConnectorConfigResponse.data.data.id
          );
          newConfigId = setConnectorConfigResponse.data.data.id;

          return true;
        }
      } catch (err) {
        console.log("err:", err);
        setInputValues([]);

        return false;
      }
    } else {
      showMessage("Unable to set Connector Config");
    }
  };

  const testConnectorConfig = async () => {
    try {
      let stringifiedData = JSON.stringify({
        id: newConfigId,
      });

      let testConnectorConfigResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/testConnectorConfigs",
        stringifiedData
      );

      if (testConnectorConfigResponse.data.status == "OK") {
        setIsConfigApisBeingCalled(false);

        return true;
      }
    } catch (err) {
      console.log("err:", err);
      setIsConfigApisBeingCalled(false);
      return false;
    }
  };

  const handleFilterClick = () => {
    setFilterModalOpen(!filterModalOpen);
  };

  const updateConnectorsByFilters = (categoriesArray, subCategoriesArray) => {
    const selectedCategories = categoriesArray.filter(
      (category) => category.isSelected
    );
    const selectedSubCategories = subCategoriesArray.filter((subCategory) =>
      subCategory.lists.some((list) => list.isChecked)
    );

    if (selectedCategories.length === 0 && selectedSubCategories.length === 0) {
      setConnectorsArrState(initialConnectorsArr);
    } else {
      const matchedObjects = initialConnectorsArr.filter((connector) => {
        const isCategoryMatched = selectedCategories.some(
          (category) => category.title.toUpperCase() === connector.type
        );

        const isSubCategoryMatched = selectedSubCategories.some(
          (subCategory) => subCategory.title.toUpperCase() === connector.type
        );

        return isCategoryMatched && isSubCategoryMatched;
      });

      setConnectorsArrState(matchedObjects);
    }

    // Update temporary category and subcategory states
    setTempCategoryArray(categoriesArray);
    setTempSubCategoryArray(subCategoriesArray);

    // Toggle the filter modal
    setFilterModalOpen(!filterModalOpen);
  };

  const handleAddPipeLineClicked = (
    selectedConnectorName,
    selectedConnectorType
  ) => {
    console.log(selectedConnectorType, "tyyy");
    console.log(selectedConnectorName, "nnnn");
    setSelectedConnectorType(selectedConnectorType);
    setSelectedConnectorName(selectedConnectorName);
    setIsAddPipelineClicked(!isAddPipelineClicked);
  };

  const handleCancelClick = (stepNumber) => {
    if (stepNumber == 1) {
      setIsAddPipelineClicked(false);
    }
  };

  const getWorkspace = async () => {
    let selectedWorkspaceId = localStorage.getItem("selectedWorkSpaceId");
    setSelectedWorkspaceId(selectedWorkspaceId);
    let stringifyData = { id: selectedWorkspaceId };
    let getWorkSpace = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "pipeline/getWorkSpace",
      stringifyData
    );

    if (
      getWorkSpace &&
      getWorkSpace.data &&
      getWorkSpace.data.data.layout != null
    ) {
      setWorkspaceLayout(getWorkSpace.data.data.layout);
      setStepperCount(3);
      return getWorkSpace.data.data.layout;
    } else {
      setStepperCount(3);

      // showMessage("Unable to fetch workspace details !");
    }
  };

  const handleNewDeployment = async (e) => {
    const stringifyData = {
      connectorId: currentConfigId,
    };
    try {
      let newDeploymentResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/setTransformDeployment",
        stringifyData
      );
      if (
        newDeploymentResponse.status === 200 ||
        newDeploymentResponse.status === 201
      ) {
        return true;
      }
    } catch (e) {
      console.error("Failed to Deploy due to....", e);
      showMessage("Deployment Failed...!");
      return false;
    }
  };

  useEffect(() => {
    getWorkspace();
  }, []);

  const getWorkspaceLayoutState = () => {
    return workspaceLayout;
  };

  const handleNextClick = async (stepNumber, selectedWorkspaceId, nodeEdge) => {
    let localWorkspaceDetails = getWorkspaceLayoutState();
    if (stepNumber == 1 && selectedConnectorType !== "TRANSFORMS") {
      let configsCheckResponse = handleConfigCheck();

      if (configsCheckResponse) {
        let setConnectorConfigResponse = await setConnectorConfigs();

        if (setConnectorConfigResponse) {
          let testConnectorConfigResponse = await testConnectorConfig();
          // FIXME:Need to remove !testConnectorConfigResponse condition
          if (testConnectorConfigResponse) {
            setCurrentConfigId(newConfigId);
            setDisableConfigInputs(true);
          } else {
            showMessage("Failed to test Connectors Config !");
          }
        } else {
          showMessage("Failed to set Connectors Config");
        }
      }
    } else if (selectedConnectorType == "TRANSFORMS") {
      // FIXME: Need to change this logic. This is a temp fix for demo puropse.
      // let setTransformConnectorConfigResponse = await setConnectorConfigs();
      let setTransformConnectorConfigResponse = true;
      setCurrentConfigId(2);
      if (setTransformConnectorConfigResponse) {
        setIsConfigApisBeingCalled(false);
        setDisableConfigInputs(true);
        showMessage("Transform Succesfully Configured!", "success");
      } else {
        setIsConfigApisBeingCalled(false);
        showMessage("Failed to set Transform Connectors Config");
      }
    }
    if (stepNumber === 2) {
      let setNewDeployment;
      let layout2 = nodeEdge.layout;

      // const setNewDeployment = true;
      // FIXME: This has to be fixed as currently the telematic device is not available we are not calling the deployment part.
      if (
        selectedSourceOfConnectors !== "Telematic Devices" &&
        selectedConnectorType !== "TRANSFORMS"
      ) {
        // TODO: Need to fix the below validation and need to put a constrain
        // if (layout2.edges.length === localWorkspaceDetails.edges.length) {
        //   showMessage("Please add an edge to connect the node");
        //   return;
        // }
        setNewDeployment = await handleNewDeployment();

        if (setNewDeployment) {
          showMessage("Deployment Successful", "success");
        }
      }

      const setWorkspaceList = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/setWorkSpace",
        nodeEdge
      );

      if (setWorkspaceList.status === 200 || setWorkspaceList.status === 201) {
        history.push({
          pathname: "connectorsPlayground",
          selectedWorkspace: selectedWorkspaceId,
        });
      }

      // TODO: Need to call setDeployment and Store new edge connection
    }
  };

  //Pass States and Functions here, you want to share with all Child Components
  const sharedData = {
    searchValue,
    filterModalOpen,
    handleFilterClick,
    updateConnectorsByFilters,
    tempCategoryArray,
    tempSubCategoryArray,
    handleAddPipeLineClicked,
    selectedConnectorName,
    inputValues,
    configs,
    handleConfigFieldsInputChange,
    handleCancelClick,
    handleNextClick,
    disableConfigInputs,
    isConfigApisBeingCalled,
    currentConfigId,
    selectedConnectorType,
    transformConnectorName,
    setTransformConnectorName,
    transformConfigParams,
    setTransformConfigParams,
    selectedSourceOfConnectors,
  };

  return (
    <ConnectorsContext.Provider value={sharedData}>
      <div className="wsConnectorMainContainer">
        <div className="wsConnectorNavBarArea">
          <div className="wsConnectorNavBarLeftArea">
            <div
              className="wsConnectorNavBarTitle"
              tour-guide="explain_connectors"
            >
              <p>Connectors</p>
            </div>
          </div>

          <div className="wsConnectorNavBarRightArea">
            {isAddPipelineClicked ? (
              <Stepper step={stepperCount} />
            ) : (
              <div className="wsConnectorNavBarRightArea">
                <div className="wsConnectorSearchArea">
                  <div className="wsConnectorSearchIcon">
                    <img src={DemoIcon} alt="" />
                  </div>
                  <div className="wsConnectorSearchTextArea">
                    <input
                      className="wsConnectorSearchTextInput"
                      type="text"
                      value={searchValue}
                      onChange={(e) => {
                        handleSearchTextChange(e.target.value);
                      }}
                      placeholder="Search for Connectors"
                    />
                  </div>
                </div>
                <div
                  className="wsConnectorFilterArea"
                  onClick={() => handleFilterClick()}
                >
                  <div className="wsConnectorFilterSign">
                    <img src={SettingsIcon} alt="" />
                  </div>
                  <p className="wsConnectorFilterText">Filters</p>
                  <div className="wsConnectorFilterDropDown">
                    <img src={expandIcon} alt="" />
                  </div>
                </div>
                {filterModalOpen && <ConnectorFilterModal />}
              </div>
            )}
          </div>
        </div>

        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner color="light" />
          </div>
        ) : connectorsArrState.length ? (
          <div className="wsConnectorCardsArea">
            {isAddPipelineClicked ? (
              <ConnectorsConfigurationPage
                workspaceDetails={workspaceLayout}
                selectedConnectorName={selectedConnectorName}
                selectedConnectorType={selectedConnectorType}
                currentConfigId={currentConfigId}
                selectedWorkspaceId={selectedWorkspaceId}
              />
            ) : (
              connectorsArrState &&
              connectorsArrState.length &&
              connectorsArrState.map((eachObj) => {
                // console.log(eachObj,"eacccc");
                return <WorkSpaceConnectorsCard data={eachObj} />;
              })
            )}
          </div>
        ) : (
          <div className="wsNoConnectorsFound">
            <p>No Connectors Found</p>
          </div>
        )}
      </div>
    </ConnectorsContext.Provider>
  );
};

export default WorkSpaceConnectorsMainPage;
