import React,{ createContext, useContext ,useCallback,useMemo} from "react";
import decode from "jwt-decode";
import { useLocalStorage } from "./hooks/useLocalStorage";
import { useHistory } from "react-router-dom/cjs/react-router-dom";



const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
 
    const [user, setUser] = useLocalStorage("token", "");
    const history = useHistory();
  
    if (user) {
      const { exp } = decode(user);
      console.log("exp1",exp);
      if (Date.now() / 1000 >exp) setUser("");
    }
    
    const login = useCallback(
      async (token) => {
        const { loginId, username } = decode(token.token);
        setUser(token.token);
        localStorage.setItem("loginId", loginId);
        localStorage.setItem("userName", username);
        localStorage.setItem("roles", token.roles);
        history.push("/onBoarding");
      },
      [history, setUser]
    );
  
    const logout = useCallback(() => {
      setUser("");
      history.push("/");
      localStorage.removeItem("token");
      localStorage.removeItem("loginId");
      localStorage.removeItem("userName");
      localStorage.removeItem("selectedWorkSpaceName")
      localStorage.removeItem("selectedWorkSpaceId")
      localStorage.removeItem("selectedWorkSpaceIconLink")
      localStorage.removeItem("roles")
    }, [history, setUser]);
  
    const value = useMemo(
      () => ({
        user,
        login,
        logout,
      }),
      [user, login, logout]
    );
  
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
  
};

export const useAuth = () => {
  return useContext(AuthContext);
};