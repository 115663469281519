import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Spinner,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Label,
  FormFeedback,
  FormGroup,
} from "reactstrap";
import Editor from "@monaco-editor/react";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { AES } from "crypto-js";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
// import { explorer } from "./folderData";
import Folder from "./Folder";
import BubbleLoader from "./BubbleLoader";
import ButtonAvatar from "../common/_buttonAvatar";
import {
  Redirect,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

const CondenseEditor = () => {
  // variables realted to redirections
  const location = useLocation();
  const [redirectToBuildPage, setRedirectToBuildPage] = useState(false);

  const { showMessage } = useContext(SnackbarContext);

  const [explorerData, setExplorerData] = useState({});
  const [tempExplorerData, setTempExplorerData] = useState({});
  const [newlyAddedFolderOrFile, setNewlyAddedFolderOrFile] = useState([]);
  const [dataToCommit, setDataToCommit] = useState([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [editorValueToShow, setEditorValueToShow] = useState("");
  const [selectedFileId, setSelectedFileId] = useState("");
  const [tempoCode, setTempoCode] = useState("");
  const [isTabClosed, setIsTabClosed] = useState(false);
  const [isRightClicked, setIsRightClicked] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [isTabHover, setIsTabHover] = useState(false);

  //States related to GitHub
  const [githubUserData, setGithubUserData] = useState({});
  const [githubAccountDropdown, setGithubAccountDropdown] = useState(false);
  const [gitUsername, setGitUsername] = useState("");
  const [allRepos, setAllRepos] = useState([]);
  const [allBranches, setAllBranches] = useState([]);
  const [allCommits, setAllCommits] = useState([]);
  const [selectedRepo, setSelectedRepo] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [isFolderTreeFetching, setIsFolderTreeFetching] = useState(false);

  //Modal states for Commit Message and PAT token
  const [modal, setModal] = useState(false);
  const [PATtoken, setPATtoken] = useState("");
  const [commitMessageModal, setCommitMessageModal] = useState(false);
  const [commitMessage, setCommitMessage] = useState("");
  const [isCodeBeingCommited, setIsCodeBeingCommited] = useState(false);

  //States for ChatGpt
  const [chapGptModal, setChatGptModal] = useState(false);
  const [chatGptQuery, setChatGptQuery] = useState("");
  const [chatGptQueryResult, setChatGptQueryResult] = useState("");
  const [isQueryBeingExecuted, setIsQueryBeingExecuted] = useState(false);

  // States related to Build user input
  const [isBuildInputModalOpen, setBuildInputModalOpen] = useState(false);
  const [imageName, setImageName] = useState("");
  const [tag, setTag] = useState("");
  const [imageTagValidity, setImageTagValidity] = useState(false);
  const imageTagRegex =
    /^(?:(?=[^:/]{1,253})(?!-)[a-zA-Z0-9-]{1,63}(?<!-)(?:\.(?!-)[a-zA-Z0-9-]{1,63}(?<!-))*(?::[0-9]{1,5})?\/)?((?![._-])(?:[a-z0-9._-]*)(?<![._-])(?:\/(?![._-])[a-z0-9._-]*(?<![._-]))*)(?::(?![.-])[a-zA-Z0-9_.-]{1,128})?$/;

  const githubAccountToggle = () =>
    setGithubAccountDropdown((prevState) => !prevState);

  const chatGptQueryResponse = async () => {
    setIsQueryBeingExecuted(true);

    try {
      const stringifiedData = {
        query: chatGptQuery,
      };

      let response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "github/queryToChatGpt",
        stringifiedData
      );

      // console.log("Repo Response: ", response.data.content);

      setChatGptQueryResult(response.data.content);
    } catch (error) {
      console.log("Error: ", error);
    }

    setIsQueryBeingExecuted(false);
  };

  const updatePATToken = async (token) => {
    try {
      const stringifiedData = {
        encodedToken: token,
      };

      let response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "github/insertPATtokenInRedis",
        stringifiedData
      );

      // console.log("Repo Response: ", response);

      showMessage(response.data.remarks);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const fetchRepoData = async () => {
    try {
      let response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "github/getAllRepo"
      );

      // console.log("Repo Response: ", response.data.allRepos);
      setGitUsername(response.data.gitUsername);
      setAllRepos(response.data.allRepos);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const getUserGithubData = async () => {
    try {
      let response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "github/getGithubUserData"
      );
      setGithubUserData(response.data);
    } catch (error) {
      console.log("Error getUserGithubData", error);
    }
  };

  const fetchBranchData = async () => {
    try {
      const stringifiedData = {
        gitUsername: gitUsername,
        gitRepo: selectedRepo,
      };

      let response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "github/getAllBranchesOfRepo",
        stringifiedData
      );

      // console.log("Branch Response: ", response.data.allBranches);
      setAllBranches(response.data.allBranches);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const fetchFolderTree = async (branchName) => {
    setExplorerData({});
    setTempExplorerData({});
    setIsFolderTreeFetching(true);

    try {
      const stringifiedData = {
        gitUsername: gitUsername,
        gitRepo: selectedRepo,
        gitBranch: branchName,
      };

      let response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "github/gitRepoFolderTree",
        stringifiedData
      );

      // console.log("Folder Tree Response: ", response.data);

      setExplorerData(response.data.folderTree);
      setTempExplorerData(response.data.folderTree);
    } catch (error) {
      console.log("Error: ", error);
    }

    setIsFolderTreeFetching(false);
  };

  const fetchCommits = async (branchName) => {
    try {
      const stringifiedData = {
        gitUsername: gitUsername,
        gitRepo: selectedRepo,
        gitBranch: branchName,
      };

      let response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "github/getAllCommitsOfBranch",
        stringifiedData
      );

      // console.log("All commits Response: ", response.data.allCommits);
      setAllCommits(response.data.allCommits);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const updateGitHubRepoResponse = async (filesToCommit) => {
    try {
      const stringifiedData = {
        gitUsername: gitUsername,
        gitRepo: selectedRepo,
        gitBranch: selectedBranch,
        filesToCommit: filesToCommit,
        commitMessage: commitMessage,
      };

      let response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "github/updateGitHubRepo",
        stringifiedData
      );

      // console.log("updateGitHubRepo Response: ", response);

      if (response.data.code === 200) {
        setDataToCommit([]);
        showMessage("Git Repo Commit Successful", "success");
      } else {
        showMessage("Git Repo Commit Failed");
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    fetchRepoData();
    getUserGithubData();
  }, []);

  useEffect(() => {
    if (selectedRepo !== "") {
      fetchBranchData();
    }
  }, [selectedRepo]);

  const handleRepoSelect = (event) => {
    setSelectedRepo(event.target.value);
    setAllBranches([]);
    setSelectedBranch("");
    setExplorerData({});
    setTempExplorerData({});
  };

  const handleBranchSelect = (event) => {
    setSelectedBranch(event.target.value);

    if (event.target.value != "Select Branch") {
      fetchFolderTree(event.target.value);
      fetchCommits(event.target.value);
    }
  };

  const handleMouseOver = () => {
    setIsTabHover(true);
  };

  const handleMouseOut = () => {
    setIsTabHover(false);
  };

  const findFileInDataToCommitArray = (id) => {
    const findObjectData = dataToCommit.filter(
      (eachObject) => eachObject.id === id
    );

    if (findObjectData.length == 0) {
      return false;
    } else {
      return findObjectData;
    }
  };

  const updateDataToCommitArray = async (id, code) => {
    const path = await findPathById(explorerData, id);

    let fileNameArray = path.split("/");

    const newObj = {
      path: path,
      id: id,
      name: fileNameArray[fileNameArray.length - 1],
      content: code,
    };

    const index = dataToCommit.findIndex((obj) => obj.path === newObj.path);

    if (index === -1) {
      setDataToCommit([...dataToCommit, newObj]);
    } else {
      setDataToCommit([
        ...dataToCommit.slice(0, index),
        { ...dataToCommit[index], content: newObj.content },
        ...dataToCommit.slice(index + 1),
      ]);
    }
  };

  const handleTabClick = async (tab) => {
    setSelectedFileId(tab.id);

    await clearEditorValue();
    for (let object of tabs) {
      if (object.id == tab.id) {
        object.isActivated = true;
      } else {
        delete object.isActivated;
      }
    }

    setEditorValueToShow(tab.code);
  };

  const handleTabClose = async (id, code) => {
    await updateDataToCommitArray(id, code);

    //Logic for updating TABS state
    let updatedTabs = tabs.filter((item) => item.id !== id);

    if (updatedTabs.length > 0) {
      setTempoCode(updatedTabs[updatedTabs.length - 1].code);

      setEditorValueToShow("");
      setTabs(updatedTabs);

      for (let object of tabs) {
        if (object.id == updatedTabs[updatedTabs.length - 1].id) {
          object.isActivated = true;
        } else {
          delete object.isActivated;
        }
      }

      setIsTabClosed(!isTabClosed);
    } else {
      setTabs([]);
      setEditorValueToShow("");
    }
  };

  useEffect(() => {
    setEditorValueToShow(tempoCode);
  }, [isTabClosed]);

  const clearEditorValue = async () => {
    await setEditorValueToShow("");
  };

  useEffect(() => {
    if (isRightClicked != false) {
      setEditorValueToShow("");
    }

    setIsRightClicked(null);
  }, [isButtonClicked]);

  const handleRightClick = (showMenuState) => {
    setIsRightClicked(showMenuState);
  };

  const updateTabsArray = async (
    selectedFileId,
    selectedFileName,
    selectedFileCode
  ) => {
    if (tabs.length > 0) {
      await clearEditorValue();
    }

    setSelectedFileId(selectedFileId);
    const found = tabs.find((element) => element.id == selectedFileId);

    if (found == undefined) {
      setTabs([
        ...tabs,
        {
          id: selectedFileId,
          name: selectedFileName,
          code: selectedFileCode,
          isActivated: true,
        },
      ]);

      setEditorValueToShow(selectedFileCode);

      if (tabs.length > 0) {
        for (let object of tabs) {
          if (object.id == selectedFileId) {
            object.isActivated = true;
          } else {
            delete object.isActivated;
          }
        }
      }
    } else {
      setEditorValueToShow(selectedFileCode);

      for (let object of tabs) {
        if (object.id == selectedFileId) {
          object.isActivated = true;
        } else {
          delete object.isActivated;
        }
      }
    }
  };

  const handleInsertNode = async (folderId, item, isFolder) => {
    const currentTime = new Date().getTime();

    const path = await findPathById(explorerData, folderId);

    const finalTree = insertNode(
      explorerData,
      folderId,
      item,
      isFolder,
      currentTime
    );
    setExplorerData(finalTree);

    setNewlyAddedFolderOrFile([
      ...newlyAddedFolderOrFile,
      {
        id: currentTime,
        name: item,
        isFolder: isFolder,
        path: path,
      },
    ]);
  };

  function findPathById(tree, id, path = "") {
    if (tree.id === id) {
      return path + tree.name;
    }

    for (let i = 0; i < tree.items.length; i++) {
      const item = tree.items[i];
      const result = findPathById(item, id, path + tree.name + "/");
      if (result) {
        return result;
      }
    }

    return null;
  }

  const insertNode = function (tree, folderId, item, isFolder, currentTime) {
    if (tree.id === folderId && tree.isFolder) {
      tree.items.unshift({
        id: currentTime,
        name: item,
        isFolder: isFolder,
        code: isFolder == true ? "" : "//Start writing code from here...",
        isSelected: false,
        items: [],
      });

      return tree;
    }

    let latestNode = [];
    latestNode = tree.items.map((ob) => {
      return insertNode(ob, folderId, item, isFolder, currentTime);
    });

    return { ...tree, items: latestNode };
  };

  const handleDeleteNode = (finalTree, selectedId) => {
    setExplorerData(finalTree);

    let updatedTabs = tabs.filter((item) => item.id !== selectedId);

    setTabs(updatedTabs);
  };

  const handleRenameNode = (finalTree, selectedId, inputValue) => {
    setExplorerData(finalTree);

    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].id === selectedId) {
        tabs[i].name = inputValue;
        break;
      }
    }
  };

  const setButtonClick = () => {
    setIsButtonClicked(!isButtonClicked);
  };

  const updateExplorer = (id, newCode, explorerData) => {
    if (explorerData.id === id) {
      explorerData.code = newCode;
    } else if (explorerData.items) {
      explorerData.items.forEach((item) => updateExplorer(id, newCode, item));
    }
  };

  function hasChildItems(item, itemId) {
    if (item.id === itemId && item.items && item.items.length > 0) {
      return true;
    }

    if (item.isFolder && item.items) {
      for (const childItem of item.items) {
        if (hasChildItems(childItem, itemId)) {
          return true;
        }
      }
    }

    return false;
  }

  const handleCommitMessageModal = async () => {
    setCommitMessageModal(!commitMessageModal);
  };

  const handleCommit = async () => {
    setIsCodeBeingCommited(true);

    const filteredNewFiles = newlyAddedFolderOrFile.filter(
      (eachObject) => eachObject.isFolder === false
    );
    // console.log("filteredNewFiles:", filteredNewFiles);

    const filteredNewFolders = newlyAddedFolderOrFile.filter(
      (eachObject) => eachObject.isFolder === true
    );
    // console.log("filteredNewFolders:", filteredNewFolders);
    // console.log("explorer data:", explorerData);

    let isEmptyFolder = false;

    for (const folder of filteredNewFolders) {
      const itemId = folder.id;
      const hasItems = hasChildItems(explorerData, itemId);

      if (!hasItems) {
        isEmptyFolder = true;
        break;
      }
    }

    let finalArray = [];

    if (isEmptyFolder == true) {
      showMessage("Cannot Commit Empty Folder");
    } else {
      const resultArray = filteredNewFiles.map((file) => {
        const itemId = file.id;
        const path = file.path;
        const name = file.name;

        function findContentById(item, itemId) {
          if (item.id === itemId) {
            return item.code || "";
          }

          if (item.isFolder && item.items) {
            for (const childItem of item.items) {
              const content = findContentById(childItem, itemId);
              if (content !== "") {
                return content;
              }
            }
          }

          return "";
        }

        const content = findContentById(explorerData, itemId);

        return {
          path,
          id: itemId,
          name,
          content,
        };
      });

      const mergedArray = [
        ...filteredNewFiles,
        ...resultArray,
        ...dataToCommit,
      ];

      for (let j in mergedArray) {
        const path = mergedArray[j].path;
        const updatedPath = path.replace(/^root\//, "");

        finalArray.push({
          path: updatedPath,
          content: mergedArray[j].content ? mergedArray[j].content : " ",
        });
      }

      // console.log("finalArray:", finalArray);

      await updateGitHubRepoResponse(finalArray);
    }
    setIsCodeBeingCommited(false);
    setCommitMessageModal(false);
  };

  const handlePATbutton = async () => {
    setModal(!modal);
  };

  const loginWithGithub = () => {
    window.location.assign(
      "https://github.com/login/oauth/authorize?client_id=" +
        env.REACT_APP_GITHUB_OAUTH_CLIENT_ID +
        "&scope=repo&redirect_uri=" +
        env.REACT_APP_URL +
        "oauth/authorize"
    );
  };

  const logoutUserFromGithub = async () => {
    let response = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "github/logoutUserFromGithub"
    );
    if (response.data.success) {
      window.location.reload();
    } else {
      console.log("Logout failed");
    }
  };

  const submitPATtoken = async () => {
    const encryptedData = AES.encrypt(
      PATtoken,
      process.env.REACT_APP_GITHUB_SECRET
    ).toString();
    // console.log("Encrypted Data:", encryptedData);

    await updatePATToken(encryptedData);
    setModal(!modal);
    setPATtoken("");
  };

  const handleEditorChange = async (value, event) => {
    // here is the current value
    // console.log("value:", value);
    // console.log("event:", event);

    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].id === selectedFileId) {
        tabs[i].code = value;
        setTabs(tabs);
        break;
      }
    }

    await updateExplorer(selectedFileId, value, explorerData);
    await setExplorerData(explorerData);
    await updateDataToCommitArray(selectedFileId, value);
  };

  const handleChatGptModal = () => {
    setChatGptModal(!chapGptModal);
  };

  const handleQueryToChatGpt = async () => {
    setChatGptQueryResult("");
    chatGptQueryResponse();
  };

  const handleEditorDidMount = (editor, monaco) => {
    // console.log("onMount: the editor instance:", editor);
    // console.log("onMount: the monaco instance:", monaco);
  };

  const handleEditorWillMount = (monaco) => {
    // console.log("beforeMount: the monaco instance:", monaco);
  };

  const handleEditorValidation = (markers) => {
    // model markers
    // markers.forEach(marker => console.log('onValidate:', marker.message));
  };

  const handleBuildUserInputModal = () => {
    setBuildInputModalOpen((prev) => !prev);
  };

  // Redirect to homepage when there is no indication that this page was opened through redirection
  if (!Boolean(location.state?.isRedirected))
    return <Redirect to={{ pathname: "/" }} />;

  // Redirect to Build page where all the require props are collected for build process and user clicks build in the Modal
  if (redirectToBuildPage)
    return (
      <Redirect
        to={{
          pathname: "/build",
          state: {
            isRedirected: true,
            imageName: imageName,
            tag: tag,
            repoName: selectedRepo,
            branch: selectedBranch,
            username: gitUsername,
            commitName: commitMessage,
          },
        }}
      />
    );

  return (
    <>
      <Row xs={12} style={{ textAlign: "start", marginBottom: "10px" }}>
        <Col xs={2}>
          <select
            className="form-select codeEditorFormSelect"
            aria-label="Default select example"
            onChange={handleRepoSelect}
          >
            <option selected>Select Repo</option>
            {allRepos.map((eachItem) => {
              return (
                <option value={eachItem.repoName}>{eachItem.repoName}</option>
              );
            })}
          </select>
        </Col>

        <Col xs={2}>
          <select
            className={
              selectedRepo == "" || selectedRepo == "Select Repo"
                ? "form-select codeEditorFormSelectDisabled"
                : "form-select codeEditorFormSelect"
            }
            aria-label="Default select example"
            disabled={selectedRepo == ""}
            onChange={handleBranchSelect}
          >
            <option selected>Select Branch</option>
            {allBranches.map((eachItem) => {
              return (
                <option value={eachItem.branchName}>
                  {eachItem.branchName}
                </option>
              );
            })}
          </select>
        </Col>

        <Col xs={2}>
          <select
            className={
              selectedBranch == "" || selectedBranch == "Select Repo"
                ? "form-select codeEditorFormSelectDisabled"
                : "form-select codeEditorFormSelect"
            }
            // class="form-select"
            aria-label="Default select example"
            disabled={selectedBranch == ""}
            onChange={() =>
              console.log("TODO: Commit Functionality is Pending to Implement")
            }
          >
            <option selected>All Commits</option>
            {allCommits.map((eachItem) => {
              return (
                <option value={eachItem.commitMessage}>
                  {eachItem.commitMessage}
                </option>
              );
            })}
          </select>
        </Col>

        <Col xs={2}>
          <button
            type="button"
            className={
              dataToCommit.length < 1
                ? "btn btn-primary codeEditorCommitButtonDisabled"
                : "btn btn-primary codeEditorCommitButton"
            }
            onClick={handleCommitMessageModal}
            disabled={dataToCommit.length < 1}
          >
            Commit Changes
          </button>
        </Col>

        <Col xs={2}>
          <button
            type="button"
            className="btn btn-primary codeEditorGitButtonBackground"
            onClick={handleChatGptModal}
          >
            Chat GPT
          </button>
        </Col>

        <Col xs={2}>
          {!Object.keys(githubUserData).length ? (
            <button
              type="button"
              className="btn btn-primary codeEditorGitButtonBackground"
            >
              <Spinner style={{ width: "inherit", height: "inherit" }} />
            </button>
          ) : githubUserData.isUserFound ? (
            <>
              <button
                type="button"
                className="btn btn-primary codeEditorGitButtonBackground"
                style={{ display: "flex" }}
                onClick={githubAccountToggle}
              >
                <ButtonAvatar
                  alt={"User Avatar"}
                  src={githubUserData.avatarUrl}
                />
                {githubUserData.username}
              </button>
              <Dropdown
                isOpen={githubAccountDropdown}
                toggle={githubAccountToggle}
                direction={"down"}
              >
                <DropdownMenu>
                  <DropdownItem onClick={logoutUserFromGithub}>
                    Logout of Github
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </>
          ) : (
            <button
              type="button"
              className="btn btn-primary codeEditorGitButtonBackground"
              onClick={loginWithGithub}
            >
              SignIn with Github
            </button>
          )}
        </Col>
      </Row>

      <Modal isOpen={modal} toggle={handlePATbutton} className="custom-modal">
        <ModalHeader toggle={handlePATbutton}>Github PAT Token</ModalHeader>
        <ModalBody>
          <Input
            type="text"
            value={PATtoken}
            onChange={(e) => setPATtoken(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={submitPATtoken}>
            Submit Token
          </Button>
          <Button color="secondary" onClick={handlePATbutton}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={commitMessageModal}
        toggle={handleCommitMessageModal}
        className="custom-modal"
      >
        <ModalHeader toggle={handleCommitMessageModal}>
          Commit Message
        </ModalHeader>
        <ModalBody>
          <Input
            type="text"
            value={commitMessage}
            onChange={(e) => setCommitMessage(e.target.value)}
          />
        </ModalBody>

        {isCodeBeingCommited ? (
          <div style={{ marginBottom: "15px" }}>
            <BubbleLoader />
          </div>
        ) : (
          <ModalFooter>
            <Button color="primary" onClick={handleCommit}>
              Submit
            </Button>
            <Button color="secondary" onClick={handleCommitMessageModal}>
              Cancel
            </Button>
          </ModalFooter>
        )}
      </Modal>

      <Modal
        isOpen={chapGptModal}
        toggle={handleChatGptModal}
        fullscreen
        className="custom-modal"
      >
        <ModalHeader toggle={handleChatGptModal}>Write Your Query</ModalHeader>
        <ModalBody>
          <div style={{ marginBottom: "15px" }}>
            <Input
              type="text"
              value={chatGptQuery}
              onChange={(e) => setChatGptQuery(e.target.value)}
              style={{
                backgroundColor: "#505050",
                color: "white",
                marginBottom: "15px",
              }}
            />

            <Button
              style={{ marginRight: "20px" }}
              color="primary"
              onClick={handleQueryToChatGpt}
            >
              Submit
            </Button>
            <Button color="secondary" onClick={handleChatGptModal}>
              Close
            </Button>
          </div>

          <div>
            <p style={{ fontSize: "18px" }}>Query Result</p>
            <pre
              style={{
                height: "37rem",
                backgroundColor: "#505050",
                color: "#fff",
                overflow: "auto",
                padding: "15px",
                borderRadius: "7px",
                border: "1px solid white",
                fontFamily: "monospace",
                whiteSpace: "pre-wrap",
              }}
            >
              {isQueryBeingExecuted ? (
                <BubbleLoader />
              ) : (
                <code style={{ display: "block" }}>{chatGptQueryResult}</code>
              )}
            </pre>
          </div>
        </ModalBody>
      </Modal>

      <Col xs={12} className="codeEditorMainCol">
        <Row className="codeEditorMainRow">
          <Col xs={3} className="codeEditorFolderTree">
            {(isButtonClicked == true || isButtonClicked == false) && (
              <Folder
                handleInsertNode={handleInsertNode}
                handleDeleteNode={handleDeleteNode}
                handleRenameNode={handleRenameNode}
                explorer={explorerData}
                tempExplorerData={tempExplorerData}
                setButtonClick={setButtonClick}
                updateTabsArray={updateTabsArray}
                isFolderTreeFetching={isFolderTreeFetching}
                handleRightClick={handleRightClick}
                findFileInDataToCommitArray={findFileInDataToCommitArray}
              />
            )}
          </Col>

          <Col xs={9} className="codeEditorEditorArea">
            <div style={{ display: "flex", flexDirection: "row" }}>
              {tabs.length
                ? tabs.map((tab) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div>
                          <button
                            key={tab.id}
                            className={
                              tab.isActivated
                                ? "codeEditorTabs1"
                                : "codeEditorTabs2"
                            }
                            onClick={() => handleTabClick(tab)}
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                          >
                            {tab.name}
                          </button>
                        </div>

                        <div>
                          <button
                            onClick={() => handleTabClose(tab.id, tab.code)}
                            style={{
                              visibility: isTabHover ? "" : "hidden",
                            }}
                            className={
                              tab.isActivated
                                ? "codeEditorTabClose1"
                                : "codeEditorTabClose2"
                            }
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                          >
                            x
                          </button>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
            {editorValueToShow && (
              <Editor
                theme="vs-dark"
                height="56vh"
                onChange={handleEditorChange}
                onMount={handleEditorDidMount}
                beforeMount={handleEditorWillMount}
                onValidate={handleEditorValidation}
                defaultLanguage="javascript"
                options={""}
                defaultValue={editorValueToShow}
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col
        xs={12}
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button
          type="button"
          className={
            dataToCommit.length > 0
              ? "btn btn-primary codeEditorCommitButtonDisabled"
              : "btn btn-primary codeEditorCommitButton"
          }
          style={{ marginRight: 10 }}
          onClick={handleBuildUserInputModal}
          disabled={
            !Object.keys(githubUserData).length ||
            !githubUserData.isUserFound ||
            selectedRepo === "" ||
            selectedRepo === "Select Repo" ||
            selectedBranch === "" ||
            selectedBranch === "Select Branch" ||
            dataToCommit.length > 0
          }
        >
          Build
        </button>
      </Col>
      <Modal
        isOpen={isBuildInputModalOpen}
        toggle={handleBuildUserInputModal}
        className="custom-modal"
      >
        <ModalHeader toggle={handleBuildUserInputModal}>
          User Input for Build
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="imageName">Image Name</Label>
            <Input
              type="text"
              value={imageName}
              id="imageName"
              onChange={(e) => {
                setImageName(e.target.value);
                setImageTagValidity(
                  imageTagRegex.test(e.target.value + ":" + tag)
                );
              }}
              placeholder="Enter Image Name"
            />
            <Label for="tag" style={{ marginTop: "10px" }}>
              Tag value
            </Label>
            <Input
              type="text"
              id="tag"
              value={tag}
              onChange={(e) => {
                setTag(e.target.value);
                setImageTagValidity(
                  imageTagRegex.test(imageName + ":" + e.target.value)
                );
              }}
              invalid={!imageTagValidity}
              placeholder="Enter Tag Value"
            />
            <FormFeedback>
              Combination of Image and Tag is not in accordance with standard
              docker image:tag format
            </FormFeedback>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <button
            type="button"
            className={
              tag.length === 0 || imageName.length === 0
                ? "btn btn-primary codeEditorCommitButtonDisabled"
                : "btn btn-primary codeEditorCommitButton"
            }
            onClick={() => setRedirectToBuildPage(true)}
            disabled={
              tag.length === 0 || imageName.length === 0 || !imageTagValidity
            }
          >
            Build
          </button>
          <button
            type="button"
            className={"btn btn-primary codeEditorCommitButton"}
            onClick={() => {
              setImageName("");
              setTag("");
              setImageTagValidity(false);
              handleBuildUserInputModal();
            }}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CondenseEditor;
