import ReactFlow, {
  addEdge,
  ConnectionLineType,
  Controls,
  applyEdgeChanges,
  applyNodeChanges,
  MiniMap,
  Panel,
  ReactFlowProvider,
  useReactFlow,
} from "reactflow";
import { useHistory } from "react-router-dom";
import ConnectorsAddButton from "../../components/connectorsComponents/_connectorsAddButton";
import React, { useCallback, useState, useContext } from "react";
import dagre from "dagre";
import axiosAdapter from "../../utils";
import { Button, Col, Input, Row } from "reactstrap";
import ConnectorsCard from "../../components/connectorsComponents/_connectorsCard";
import ConnectorCardDashed from "../../components/connectorsComponents/_connectorsCardDashed";
import "reactflow/dist/style.css";
import { useEffect } from "react";
import { env } from "../../env";
import closeModal from "../../assets/images/closeModal.png";
import cancelModal from "../../assets/images/cancel.png";
import MessageIcon from "../../../src/assets/images/demo.jpeg";
import ConnectorsAddedSource from "./ConnectorsAddedSource";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import Typography from "../../components/common/_typography";
import TerminalConnector from "./ConnectorsTerminal";
import StyledPauseButton from "../../components/common/_pauseButton";
import { io } from "socket.io-client";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
const position = { x: 10, y: 10 };
const edgeType = "smoothstep";

let initialNodes = [];

let initialEdges = [];

let configIdArray = [];

const nodeWidth = 400;
const nodeHeight = 150;

const getLayoutedElements = (nodes, edges) => {
  if (nodes && edges) {
    // Find nodes that have no outgoing edges
    const nodesWithoutTarget = nodes
      .filter((node) => {
        return !edges.some((edge) => edge.source === node.id);
      })
      .map((node) => node.id);

    // console.log("nodesWithoutTarget:", nodesWithoutTarget);

    //Graph Related Logics
    dagreGraph.setGraph({ rankdir: "LR" });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node, i) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = "left";
      node.sourcePosition = "right";
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
      if (node.position.x == 0 && node.position.y == 0) {
        node.position.x = 30;
        node.position.y = 30;
      } else if (node.position.x == 0) {
        node.position.x = 30;
      } else if (node.position.y == 0) {
        node.position.y = 30;
      }

      // Modifying data object of node
      // Check if the node's id is present in nodesWithoutTarget array
      if (nodes.length > 1) {
        node.data.isNodeWithoutTarget = nodesWithoutTarget.includes(node.id);
      } else {
        node.data.isNodeWithoutTarget = true;
      }

      //Inserting key "firstNode" = true, if node id is 1
      //This is being inserted only to hide , left side dot from node card from UI, if node card is first one
      if (node.id == 1) {
        node.data.firstNode = true;
      } else {
        node.data.firstNode = false;
      }

      //Picking all config Ids
      if (node.data?.configId) {
        configIdArray.push(node.data?.configId);
      }

      node.data.allConfigIds = configIdArray;

      return node;
    });

    return { nodes, edges };
  }
};

const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
  initialNodes,
  initialEdges
);

const nodeTypes = {
  customDashed: ConnectorCardDashed,
  custom: ConnectorsCard,
};

const nodeColor = (node, e) => {
  if (node?.data?.label) {
    switch (node.data.label) {
      case "input":
        return "#6ede87";
      case "output":
        return "#6865A5";
      default:
        return "#ff0072";
    }
  }
};

const Flow = (props) => {
  const { showMessage } = useContext(SnackbarContext);
  const { setViewport } = useReactFlow();
  let {
    setModalOpen,
    isModalOpen,
    isModalAddedConnectors,
    isModalTypePopover,
    isModalPopOver,
    setHandleTidyUp,
    disableTidyUp,
    setConfigIdForTerminal,
  } = props;
  const [nodes, setNodes] = useState(layoutedNodes);
  const [edges, setEdges] = useState(layoutedEdges);
  const [isSelectedNode, setSelectedNode] = useState(null);
  const [selectedNodeState, setSelectedNodeState] = useState(null);
  const [selectedWorkSpaceIconLink, setSelectedWorkSpaceIconLink] =
    useState("");

  const handleTidyUp = useCallback(async () => {
    let alignedWorkspaceList;

    if (
      props.workspaceLayout &&
      props.workspaceLayout.node &&
      props.workspaceLayout.edges
    ) {
      alignedWorkspaceList = getLayoutedElements(
        props.workspaceLayout.node,
        props.workspaceLayout.edges
      );
      if (
        alignedWorkspaceList &&
        alignedWorkspaceList.nodes &&
        alignedWorkspaceList.edges
      ) {
        setViewport({ x: 0, y: 0, zoom: 1 });
        setNodes(alignedWorkspaceList.nodes);
        setEdges(alignedWorkspaceList.edges);
      }
    }
  });

  useEffect(() => {
    let fetchWorkSpaceIdTemp = props.fetchWorkSpaceId;
    fetchWorkSpaceIdTemp(isSelectedNode, selectedNodeState);
  }, [isSelectedNode, selectedNodeState]);

  useEffect(() => {
    let alignedWorkspaceList;
    if (
      props.workspaceLayout &&
      props.workspaceLayout.node &&
      props.workspaceLayout.edges
    ) {
      alignedWorkspaceList = getLayoutedElements(
        props.workspaceLayout.node,
        props.workspaceLayout.edges
      );
      if (
        alignedWorkspaceList &&
        alignedWorkspaceList.nodes &&
        alignedWorkspaceList.edges
      ) {
        setNodes(alignedWorkspaceList.nodes);
        setEdges(alignedWorkspaceList.edges);
      }
    }

    //Setting SelectedWorkSpaceIconLink
    setSelectedWorkSpaceIconLink(
      localStorage.getItem("selectedWorkSpaceIconLink")
    );
  }, [props.workspaceLayout]);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({ ...params, type: ConnectionLineType.Bezier }, eds)
      ),
    []
  );

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onNodeSelectedChange = (i, e) => {
    setConfigIdForTerminal(e.data.configId);
    setSelectedNode(e.id);
    setSelectedNodeState(e.data.isConnector);
    isModalTypePopover(true);
    if (e.data.isConnector === false) {
      setModalOpen(true);
    }
    if (e.data.isConnector === true) {
      if (isModalOpen) {
        showMessage("Please close the log stream", "success");
      }
      if (!isModalOpen) {
        isModalAddedConnectors(true);
      }
    }
  };

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodesConnectable={false}
        onNodeClick={onNodeSelectedChange}
        connectionLineType={ConnectionLineType.Bezier}
      >
        {/* <MiniMap nodeColor={nodeColor} /> */}
        <div className="reactFlowChatIcon">
          <img src={selectedWorkSpaceIconLink} alt="" height="48px" />
        </div>
        <Panel position="bottom-left">
          <ConnectorsAddButton handleTidyUp={handleTidyUp} />
        </Panel>
        {props.children}
      </ReactFlow>
    </>
  );
};

const ConnectorsAddedComponents = (props) => {
  const history = useHistory();

  const { showMessage } = useContext(SnackbarContext);
  const [workspaceLayout, setWorkspaceLayout] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalAddedConnectors, setModalAddedConnectors] = useState(false);
  const [isTerminalButtonClicked, setIsTerminalButtonClicked] = useState(false);
  const [isModalPopOver, setIsModalPopOver] = useState(false);
  const [selectedWorkSpace, setSelectWorkSpace] = useState(null);
  const [selectedWorkSpaceType, setSelectedWorkspaceType] = useState(null);
  const [nodeConfigIds, setNodeConfigIds] = useState(configIdArray);
  const [handleTidyUpState, setHandleTidyUp] = useState(false);
  const [configIdForTerminal, setConfigIdForTerminal] = useState(null);

  const [socket, setSocket] = useState(null);
  const [statusMessage, setStatusMessage] = useState("paused");
  const [messages, setMessages] = useState([]);
  // const [isTerminalResumed, setIsTerminalResumed] = useState(false);
  const [topicName, setTopicName] = useState("");
  const [time, setTime] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [isEmpty, setIsEmpty] = useState("");
  const [terminalStatus, setTerminalStatus] = useState(false);

  const setNewTopicName = async () => {
    try {
      let stringifiedData = JSON.stringify({
        connectorId: configIdForTerminal,
      });

      let setNewTopicNameResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getConfiguredConnector",
        stringifiedData
      );

      let data = JSON.parse(setNewTopicNameResponse.data.data.config);

      if (
        setNewTopicNameResponse.data.status == "OK" &&
        data.topicName != undefined
      ) {
        setTopicName(data.topicName);
        return { status: true, topicName: data.topicName };
      } else if (
        setNewTopicNameResponse.data.status == "OK" &&
        data.topic != undefined
      ) {
        setTopicName(data.topic);
        return { status: true, topicName: data.topic };
      } else if (
        setNewTopicNameResponse.data.status == "OK" &&
        data.kafkaTopic != undefined
      ) {
        setTopicName(data.kafkaTopic);
        return { status: true, topicName: data.kafkaTopic };
      } else if (
        setNewTopicNameResponse.data.status == "OK" &&
        (data.topicName === undefined || data.topic || data.kafkaTopic)
      ) {
        setModalOpen(false);
        showMessage("Topic Name Unavailable");
      }
    } catch (err) {
      console.log("err:", err);

      return false;
    }
  };

  useEffect(() => {
    const connectToSocket = async () => {
      if (isTerminalButtonClicked) {
        const setNewTopicNameResponse = await setNewTopicName();

        if (setNewTopicNameResponse.status) {
          const socket = io(env.REACT_APP_SOCKET_IO_URL, {
            path: "/logs",
          });

          setSocket(socket);
          socket.on("connect", async () => {
            console.log("socket connected");
          });
          if (socket) {
            setStatusMessage("connected");
          }
          try {
            socket.emit("startConsumer", {
              newEvent: setNewTopicNameResponse?.topicName,
            });
          } catch (error) {
            console.error("Error emitting startConsumer event:", error);
            showMessage("Error emitting startConsumer event");
          }
        } else {
          showMessage("Failed to fetch topic name");
        }
      }
    };

    connectToSocket();
  }, [isTerminalButtonClicked]);

  const getLogsData = async () => {
    if (socket) {
      const setNewTopicNameResponse = await setNewTopicName();
      let stringifiedData = JSON.stringify({
        socketId: socket.id,
        topic: setNewTopicNameResponse?.topicName,
      });

      let message = await axiosAdapter(
        "POST",
        env.REACT_APP_SOCKET_IO_URL + "getKafkaLogs",
        stringifiedData
      );
      setMessages(message.data.logs);
      if (message.data.logs.length == 0) {
        setIsEmpty("No data found");
        setMessages([]);
      } else {
        setIsEmpty("");
      }
      return message.data.logs;
    }
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };
  const handleStartButton = async () => {
    if (terminalStatus) {
      showMessage("Terminal already started");
    } else {
      if (!isNaN(time) && time > 0) {
        const id = setInterval(async () => {
          const newContent = await getLogsData();
          setMessages(newContent);
        }, time * 1000);
        setIntervalId(id);
        console.log("messageslength", messages.length);
        setTerminalStatus(true);
        // if (isTerminalResumed || messages.length != undefined) {
        //   setIsTerminalResumed(false);
        // }

        console.log("Terminal started");
        showMessage("Terminal Started successfully", "success");
      } else {
        showMessage("Enter valid time input");
      }
    }
  };
  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  const handleStopButton = async () => {
    clearInterval(intervalId);
    // setIsTerminalResumed(true);
    if (terminalStatus) {
      setTerminalStatus(false);
      console.log("Terminal paused successfully");
      showMessage("Terminal paused successfully", "success");
    } else {
      showMessage("Terminal already paused");
    }
  };

  const handlePullButton = async () => {
    setTerminalStatus(true);
    getLogsData();
  };

  async function fetchData() {
    let selectedWorkspaceId = localStorage.getItem("selectedWorkSpaceId");
    let stringifyData = { id: selectedWorkspaceId };
    let getWorkSpace = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "pipeline/getWorkSpace",
      stringifyData
    );

    if (
      getWorkSpace &&
      getWorkSpace.data &&
      getWorkSpace.data.data.layout != null
    ) {
      setWorkspaceLayout(getWorkSpace.data.data.layout);
      return true;
    } else {
      history.push({
        pathname: "onBoarding",
        onBoardingState: 3,
        selectedWorkspaceId: selectedWorkspaceId,
      });
    }
  }

  useEffect(() => {
    if (props.selectedWorkSpaceId) {
      localStorage.setItem("selectedWorkSpaceId", props.selectedWorkSpaceId);
    }
    fetchData();
  }, [props.selectedWorkSpaceId]);

  const setModalOpenFunction = (e) => {
    setModalOpen(e);

    if (e === false) {
      //socket.emit("disconnectConsumer",{ newEvent: topicName });
      setTerminalStatus(false);
      socket.disconnect();
    }

    if (isTerminalButtonClicked) {
      setIsTerminalButtonClicked(false);
      setMessages([]);
    }
  };

  const isModalAddedConnectorsFunction = (e) => {
    setModalAddedConnectors(e);
    fetchData();
  };

  const isModalTypePopoverFunction = () => {
    setIsModalPopOver(true);
  };

  const handleRefetchNodes = async () => {
    let isFetchSuccess = await fetchData();
    if (isFetchSuccess) {
      showMessage("Deleted the node successfully", "success");
      setModalOpen(false);
      setIsModalPopOver(false);
    }
  };

  const setFetchWorkSpaceId = (e, i) => {
    // console.log("This is e, i", e, i);
    setSelectWorkSpace(e);
    setSelectedWorkspaceType(i);
  };

  const handleDeleteNode = async () => {
    let removeWorkspaceId;
    let removeConfigId;
    let selectedWorkspaceNodeList = [];
    let selectedWorkspaceEdgesList = [];
    let deleteableEdges = [];
    let tempWorkSpaceId = workspaceLayout;
    let selectedWorkSpaceTemp =
      selectedWorkSpace && JSON.parse(selectedWorkSpace);
    let nodeLength = tempWorkSpaceId.node.length;
    let edgeLength = tempWorkSpaceId.edges.length;
    if (selectedWorkSpaceTemp > 1 && selectedWorkSpaceType === false) {
      const sourceSet = new Set();

      console.log("selectedWorkSpaceTemp.....", selectedWorkSpaceTemp);

      for (let i = 0; i < edgeLength; i++) {
        sourceSet.add(tempWorkSpaceId.edges[i].source);
      }

      for (let i = 0; i < edgeLength; i++) {
        if (!sourceSet.has(tempWorkSpaceId.edges[i].target)) {
          deleteableEdges.push(tempWorkSpaceId.edges[i].target);
        }
      }

      for (let i = 0; i < nodeLength; i++) {
        if (
          selectedWorkSpaceTemp == tempWorkSpaceId.node[i].id &&
          deleteableEdges.includes(tempWorkSpaceId.node[i].id)
        ) {
          removeWorkspaceId = tempWorkSpaceId.node[i].id;
          removeConfigId = tempWorkSpaceId.node[i].data.configId;
        } else {
          selectedWorkspaceNodeList.push(tempWorkSpaceId.node[i]);
        }
      }

      if (removeWorkspaceId && removeConfigId) {
        for (let i = 0; i < edgeLength; i++) {
          let sourceValue =
            tempWorkSpaceId.edges[i].source &&
            JSON.parse(tempWorkSpaceId.edges[i].source);
          let targetValue =
            tempWorkSpaceId.edges[i].target &&
            JSON.parse(tempWorkSpaceId.edges[i].target);
          let tempSource = tempWorkSpaceId.edges[i];

          if (sourceValue < targetValue || !targetValue) {
            if (tempWorkSpaceId.edges[i].target != removeWorkspaceId) {
              selectedWorkspaceEdgesList.push(tempSource);
            }
          }
        }

        let nodeDeleteId = {
          connectorId: removeConfigId,
        };
        let selectedWorkspaceId = localStorage.getItem("selectedWorkSpaceId");

        let stringifyData = {
          id: selectedWorkspaceId,
          layout: {
            node: selectedWorkspaceNodeList,
            edges: selectedWorkspaceEdgesList,
          },
        };

        try {
          let deleteKubeApiNode = await axiosAdapter(
            "POST",
            env.REACT_APP_URL + "pipeline/deleteNamespaceDeployment",
            nodeDeleteId
          );
          console.log("deleteKubeApiNode......", deleteKubeApiNode.data);
        } catch (e) {
          console.error("Error: ", e);
        }

        let setWorkspaceList = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/setWorkSpace",
          stringifyData
        );

        if (setWorkspaceList && setWorkspaceList.data.status == "OK") {
          handleRefetchNodes();
        } else {
          showMessage("Failed to update the node deletion !");
        }
      } else {
        showMessage("Node deletion failed !");
      }
    } else {
      showMessage("Cannot Delete selected Node !");
      setModalOpen(false);
      setIsModalPopOver(false);
      setModalAddedConnectors(false);
    }

    console.log("selectedWorkspaceNodeList", selectedWorkspaceNodeList);
    console.log("selectedWorkspaceEdgesList", selectedWorkspaceEdgesList);
  };

  const handleTerminal = () => {
    setIsModalPopOver(false);
    setIsTerminalButtonClicked(true);
  };

  return (
    <Col xs={12} style={{ height: "calc(100vh - 80px)" }}>
      <ReactFlowProvider>
        <Flow
          workspaceLayout={workspaceLayout}
          setModalOpen={setModalOpenFunction}
          isModalAddedConnectors={isModalAddedConnectorsFunction}
          isModalTypePopover={isModalTypePopoverFunction}
          isModalOpen={isModalOpen}
          isModalPopOver={isModalPopOver}
          fetchWorkSpaceId={setFetchWorkSpaceId}
          setHandleTidyUp={handleTidyUpState}
          setConfigIdForTerminal={setConfigIdForTerminal}
        >
          {isModalPopOver && (
            <Row>
              <Col
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                  zIndex: "900",
                  backdropFilter: "blur(5px)",
                }}
              >
                <Col
                  xs={6}
                  className="addedNewConnectors"
                  style={{ height: "120px", width: "600px" }}
                >
                  <Row>
                    <Col
                      xs={12}
                      style={{
                        padding: "10px 40px",
                        justifyContent: "space-between",
                        display: "flex",
                      }}
                    >
                      <div style={{ marginLeft: "100px" }}>
                        <Typography
                          text={"Please Choose to Continue"}
                          tag="sub-head-medium"
                          color="#FAFAFA"
                        />
                      </div>

                      <img
                        src={closeModal}
                        onClick={() => {
                          setIsModalPopOver(false);
                          setModalOpen(false);
                        }}
                        height="20px"
                        style={{
                          paddingTop: "5px",
                          cursor: "pointer",
                        }}
                      />
                    </Col>

                    <Col
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Row>
                        <Col xs={6} style={{ padding: "10px" }}>
                          <Button
                            style={{
                              marginRight: "30px",
                            }}
                            size="lg"
                            color="danger"
                            outline
                            onClick={handleDeleteNode}
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} style={{ padding: "10px" }}>
                          <Button
                            size="lg"
                            color="primary"
                            onClick={handleTerminal}
                          >
                            {selectedWorkSpaceType
                              ? "New Connector"
                              : "Terminal"}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
          )}
          {isModalOpen && !isModalAddedConnectors && !isModalPopOver && (
            // TODO: Need to integrate Model for Log Streaming and Code editor
            <Row>
              <Col xs={8} className="connectorsPageModal">
                <Row>
                  <Col className="connectorsPageModalCloseBtn">
                    <img
                      src={closeModal}
                      onClick={() => {
                        setModalOpenFunction(false);
                        clearInterval(intervalId);
                        setIsEmpty("");
                        setTime(null);
                        setStatusMessage("notconnected");
                      }}
                      height="30px"
                      style={{
                        paddingTop: "15px",
                        paddingRight: "15px",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                  <Col xs={12} style={{ padding: "10px 30px" }}>
                    <Row>
                      <Col
                        xs={1}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Row>
                          <Col
                            className={
                              statusMessage == "connected"
                                ? "beaconIndicatorGreen"
                                : "beaconIndicatorRed"
                            }
                          />
                        </Row>
                      </Col>
                      <Col
                        xs={4}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          text="Input Data Source"
                          tag="head-x-small"
                          color="#FAFAFA"
                        />
                      </Col>
                      <Col
                        xs={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <Input
                              type="number"
                              value={time}
                              onChange={handleTimeChange}
                              variant="standard"
                              placeholder="Time"
                              style={{
                                backgroundColor: "white",
                                color: "black",
                              }}
                            ></Input>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xs={4}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <Button
                              onClick={handlePullButton}
                              style={{
                                backgroundColor: "#9ADE7B",
                                border: "1px solid #9ADE7B",
                                color: "#C1F2B0",
                                fontWeight: "bold",
                              }}
                            >
                              Pull
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={5}
                        style={{
                          display: "flex",
                          padding: "20px 30px",
                        }}
                      >
                        <Row>
                          <Col xs={12} style={{ padding: "15px" }}>
                            <Button color="primary" disabled>
                              <Typography
                                key={statusMessage}
                                text={[
                                  <b style={{ color: "#8BF5FA" }}>Status : </b>,
                                  statusMessage === "connected"
                                    ? "Terminal Connected"
                                    : statusMessage === "notconnected"
                                    ? "Terminal Not connected"
                                    : "",
                                ]}
                                tag="p-large"
                                color="#FAFAFA"
                              />
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xs={1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "20px 30px",
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <Button
                              onClick={handleStartButton}
                              style={{
                                backgroundColor: "#007bff",
                                border: "1px solid #007bff",
                                color: "#8BF5FA",
                                fontWeight: "bold",
                              }}
                            >
                              Play
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xs={1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <Button
                              onClick={handleStopButton}
                              style={{
                                backgroundColor: "#CE5A67",
                                border: "1px solid #CE5A67",
                                color: "#FFE7E7",
                                fontWeight: "bold",
                              }}
                            >
                              Stop
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={5}
                        style={{
                          display: "flex",
                          marginLeft: "20px",
                        }}
                      >
                        <Typography
                          key={topicName}
                          text={[
                            <b style={{ color: "#8BF5FA" }}>Topic Name : </b>,
                            topicName,
                          ]}
                          tag="p-large"
                          color="#FAFAFA"
                        />
                      </Col>
                    </Row>
                    <TerminalConnector messages={messages} isEmpty={isEmpty} />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          {isModalAddedConnectors && !isModalOpen && (
            <Row>
              <Col
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "90vh",
                  zIndex: "3001",
                  backdropFilter: " blur(5px)",
                }}
              >
                <Col
                  xs={10}
                  className="addedNewConnectors"
                  style={{ height: "85vh", overflowY: "auto" }}
                >
                  <Row>
                    <Col xs={12} className="addedNewConnectorsCloseBtn">
                      <img
                        src={cancelModal}
                        onClick={() => setModalAddedConnectors(false)}
                        height="50px"
                        style={{
                          paddingTop: "15px",
                          paddingRight: "15px",
                          cursor: "pointer",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ padding: "0px 40px" }}>
                      <ConnectorsAddedSource
                        workspaceLayout={workspaceLayout}
                        modalAddedConnectorsFunction={
                          isModalAddedConnectorsFunction
                        }
                        nodeConfigIds={nodeConfigIds}
                      />
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
          )}
        </Flow>
      </ReactFlowProvider>
    </Col>
  );
};

export default ConnectorsAddedComponents;
