import "../../scss/pages/BuildListStyle/_buildListpage.scss";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
const CardBuildList = styled.div`
  width: 90%;
  height: 100px;
  background: #313131;
  border: 0.1px solid #fafafa;
  border-radius: 10px;
  margin: 15px;
`;
const CondenseBuildView = () => {
  return (
    <div
      style={{
        height: "calc(100vh - 112px)",
        backgroundColor: "#171717",
        overflow: "auto",
      }}
    >
      <Row noGutters>
        <Col xs="4" className="buildListTitle">
          <h2 className="textTitleColorPrimary">Custom Transform Build List </h2>
        </Col>
      </Row>
      <Row noGutters>
        <Col xs="12">
          {[...Array(10)].map((e) => (
            <CardBuildList>
              <Row noGutters>
                <Col xs="3" className="buildCardTitleSection">
                  <Row noGutters>
                    <Col className="buildCardTitleSectionName">
                      <h5 className="textTitleColorPrimary">6T37SYUg</h5>
                      <h6 className="textTitleColorSecondary">Preview Logs</h6>
                    </Col>
                  </Row>
                </Col>
                <Col xs="3" className="buildCardTitleSection">
                  <Row noGutters>
                    <Col className="buildCardTitleSectionName">
                      <h6 className="textTitleColorPrimary">Status : Ready</h6>
                      <h5 className="textTitleColorPrimary">🟢</h5>
                    </Col>
                  </Row>
                </Col>
                <Col xs="3" className="buildCardTitleSection">
                  <Row noGutters>
                    <Col className="buildCardTitleSectionName">
                      <h6 className="textTitleColorPrimary">Status : Ready</h6>
                      <h5 className="textTitleColorPrimary">🟢</h5>
                    </Col>
                  </Row>
                </Col>
                {/* <Col xs="3">
                  <h1 className="textTitleColor">Hello</h1>
                </Col>
                <Col xs="3">
                  <h1 className="textTitleColor">Hello</h1>
                </Col>
                <Col xs="3">
                  <h1 className="textTitleColor">Hello</h1>
                </Col> */}
              </Row>
            </CardBuildList>
          ))}
        </Col>
      </Row>
    </div>
  );
};
export default CondenseBuildView;
