import "../../scss/components/buildPage/_condenseBuild.scss";
import { Col, Row } from "reactstrap";
import ReactJson from "@microlink/react-json-view";
import { useEffect, useRef, useState } from "react";
import {
  Redirect,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { io } from "socket.io-client";
import { env } from "../../env";

const CondenseBuild = () => {
  const location = useLocation();
  const [redirectToDeployConfig, setRedirectToDeployConfig] = useState(false);
  const socket = useRef(null);
  const [logs, setLogs] = useState([]);
  const [buildStatus, setBuildStatus] = useState("In Progress");
  const [errorResponse, setErrorResponse] = useState("");
  const [buildId, setBuildId] = useState(null);

  useEffect(() => {
    socket.current = io(env.REACT_APP_URL, {
      auth: {
        token: localStorage.getItem("token"),
      },
      path: "/docker/sockets",
    });

    const buildDockerImageResponseListener = (data) => {
      if (data.code !== 200) {
        setBuildStatus("Failed");
        setErrorResponse(data.message);
      } else {
        setBuildStatus("Success");
        setBuildId(data?.data?.buildId || null);
      }
    };

    const buildDockerImageLogListener = (data) => {
      if (
        JSON.stringify({
          stream: "\n",
        }) !== JSON.stringify(data)
      ) {
        setLogs((prevLogs) => [...prevLogs, data]);
      }
    };

    const socketDisconnectListner = (reason) => {
      if (reason === "io server disconnect") {
        socket.current.connect();
      }
    };

    if (socket.current) {
      socket.current.on(
        "buildDockerImageResponse",
        buildDockerImageResponseListener
      );
      socket.current.on("buildDockerImageLog", buildDockerImageLogListener);
      socket.current.on("disconnect", socketDisconnectListner);

      socket.current.emit("/buildDockerImage", {
        imageName: location.state?.imageName,
        tag: location.state?.tag,
        workspaceId: parseInt(localStorage.getItem("selectedWorkSpaceId")),
        inputType: "GIT",
        repoName: location.state?.repoName,
        branch: location.state?.branch,
        username: location.state?.username,
      });
    }

    return () => {
      if (socket.current) {
        socket.current.off(
          "buildDockerImageResponse",
          buildDockerImageResponseListener
        );
        socket.current.off("buildDockerImageLog", buildDockerImageLogListener);
        socket.current.off("disconnect", socketDisconnectListner);
        socket.current.disconnect();
      }
    };
  }, []);

  // Redirect to homepage when there is no indication that this page was opened through redirection
  if (!Boolean(location.state?.isRedirected))
    return <Redirect to={{ pathname: "/" }} />;

  if (redirectToDeployConfig)
    return (
      <Redirect
        to={{
          pathname: "/deploymentConfiguration",
          state: {
            isRedirected: true,
            tag: location.state?.tag,
            imageName: location.state?.imageName,
            buildId,
          },
        }}
      />
    );
  return (
    <div style={{ height: "calc(100vh - 112px)", backgroundColor: "#171717" }}>
      <Row noGutters>
        <Col xs="4" style={{ padding: "20px", paddingRight: "10px" }}>
          <div className="mainContainer">
            <div className="innerRow flexEnd mb">
              <div
                className="statusDot"
                style={{
                  backgroundColor:
                    buildStatus === "Success"
                      ? "green"
                      : buildStatus === "Failed"
                      ? "red"
                      : "yellow",
                }}
              />
            </div>
            <div className="innerRow flexStart mb">
              <p className="p">Image Name: {location.state?.imageName}</p>
            </div>
            <div className="innerRow flexStart mb">
              <p className="p">Tag Name: {location.state?.tag}</p>
            </div>
            <div className="innerRow flexStart mb">
              <p className="p">Status: Build {buildStatus}</p>
            </div>
            <div className="innerRow flexStart mb">
              <p className="p">
                Commit Name:{" "}
                {location.state?.commitName
                  ? location.state?.commitName
                  : "N/A"}
              </p>
            </div>
            <div className="innerRow flexStart mb">
              <p className="p">Branch Name: {location.state?.branch}</p>
            </div>
            <div className="innerRow flexStart mb">
              <p className="p">Repo Name: {location.state?.repoName}</p>
            </div>
            {errorResponse && (
              <div className="innerRow flexStart">
                <p className="p" style={{ color: "red" }}>
                  Response: {errorResponse}
                </p>
              </div>
            )}
          </div>
        </Col>
        <Col xs="8" style={{ padding: "20px", paddingLeft: "10px" }}>
          <div className="mainContainer">
            <div className="innerRow mb middle">
              <h3 className="h3">Build Logs</h3>
            </div>
            <div className="innerRow middle">
              <div style={{ height: "55vh", width: "100%", overflow: "auto" }}>
                <ReactJson
                  src={logs}
                  theme={"twilight"}
                  indentWidth={3}
                  groupArraysAfterLength={1000}
                  displayObjectSize={false}
                  displayDataTypes={false}
                  quotesOnKeys={false}
                  displayArrayKey={false}
                  style={{
                    backgroundColor: "#171717",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row noGutters>
        <Col
          xs={12}
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            type="button"
            className={
              buildStatus !== "Success"
                ? "btn btn-primary codeEditorCommitButtonDisabled"
                : "btn btn-primary codeEditorCommitButton"
            }
            style={{ marginRight: 40 }}
            onClick={() => setRedirectToDeployConfig(true)}
            disabled={buildStatus !== "Success"}
          >
            Deployment Configuration
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default CondenseBuild;
