import React from "react";
import Terminal, { ColorMode } from "react-terminal-ui";
import { Col, Row } from "reactstrap";
import Typography from "../../components/common/_typography";
import { Button } from "reactstrap";
import { Inspector } from 'react-inspector';
import { chromeDark} from 'react-inspector'

const TerminalConnector = (props) => {
  const { messages,isEmpty } = props;



  return (
    <Row>
      <Col xs={12} style={{ paddingTop: "30px", height: "300px"}} >
       
        <Terminal
          prompt=">"
          colorMode={ColorMode.Dark}
          height="31rem"
          maxWidth="50%"   

        >
        
            {isEmpty!=""?
            (<div>No data found</div>):
            (
            messages.map((e, index) =>{
              let parsedData=null;
              try{
                parsedData=JSON.parse(e)
              }catch(error)
              {
                
                parsedData=e;

              }
              return(
             <Col xs={12} style={{padding:"20px"}}>
              {typeof parsedData=== "object"?(
                <Inspector expandLevel={0} data={JSON.parse(e)}  theme={{...chromeDark , ...({   OBJECT_NAME_COLOR: 'rgb(229, 227, 230)',OBJECT_VALUE_STRING_COLOR: 'rgb(230, 162, 85)',TREENODE_FONT_SIZE: 15,OBJECT_VALUE_NUMBER_COLOR: 'rgb(127,199,217)',BASE_BACKGROUND_COLOR: 'rgb(37, 42, 51)'})}} ></Inspector>):(
                  <div>{parsedData}</div>
                )}
                </Col>
              )}
            ))}
        </Terminal>
      </Col>
    </Row>
  );
};

export default TerminalConnector;
