import { Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import "../../scss/components/buildPage/_condenseBuild.scss";
import {
  Redirect,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useContext, useState } from "react";
import axiosAdapter from "../../utils";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { env } from "../../env";

const DeploymentConfig = () => {
  const { showMessage } = useContext(SnackbarContext);
  const location = useLocation();
  const [deploymentName, setDeploymentName] = useState("");
  const [deploymentNameValidity, setDeploymentNameValidity] = useState(false);
  const [envVariables, setEnvVariables] = useState([
    {
      name: "",
      value: "",
    },
  ]);
  const [isBeingDeployed, setBeingDeployed] = useState(false);
  const [redirectToNewpage, setRedirectToNewpage] = useState(false);

  const handleDeploy = async () => {
    setBeingDeployed(true);

    let filteredEnvVariables = envVariables.filter(
      (variable) => Boolean(variable.name) && Boolean(variable.value)
    );
    let response;
    try {
      const stringifiedData = {
        buildId: location.state?.buildId,
        imageData: {
          name: location.state?.imageName,
          tagName: location.state?.tag,
          deploymentName,
          workspaceId: parseInt(localStorage.getItem("selectedWorkSpaceId")),
        },
        ...(filteredEnvVariables.length
          ? {
              envData: filteredEnvVariables,
            }
          : {}),
      };

      response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/updateTransformManifest",
        stringifiedData
      );

      showMessage(response.data.status);
      await (async () => {
        // Wait for 1 second to show snackbar
        await new Promise((resolve) => setTimeout(resolve, 1000));
      })();
    } catch (err) {
      console.log("Error: ", err);
    }
    setBeingDeployed(false);
    if (response) setRedirectToNewpage(true);
  };

  //   Redirect to homepage when there is no indication that this page was opened through redirection
  if (!Boolean(location.state?.isRedirected))
    return <Redirect to={{ pathname: "/" }} />;

  if (redirectToNewpage)
    return (
      <Redirect
        to={{
          pathname: "/customDeployment",
          state: {
            isRedirected: true,
            tag: location.state?.tag,
            imageName: location.state?.imageName,
            deploymentName: deploymentName,
            envVariables: envVariables.filter(
              (variable) => Boolean(variable.name) && Boolean(variable.value)
            ),
            buildId: location.state?.buildId,
          },
        }}
      />
    );

  return (
    <div style={{ height: "calc(100vh - 112px)", backgroundColor: "#171717" }}>
      <Row noGutters className="mainContainer">
        <Col
          xs="12"
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "30px",
          }}
        >
          Deployment Prerequisites
        </Col>
        <Col xs="12" style={{ paddingLeft: "20px" }}>
          <div className="innerRow flexStart mb">
            <FormGroup>
              <Label for="deploymentName">Deployment Name:</Label>
              <Input
                type="text"
                id="deploymentName"
                value={deploymentName}
                onChange={(e) => {
                  setDeploymentName(e.target.value);
                  setDeploymentNameValidity(
                    /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/.test(
                      e.target.value
                    )
                  );
                }}
                invalid={!deploymentNameValidity}
                style={{
                  backgroundColor: "#505050",
                  color: "white",
                }}
              />
              <FormFeedback>
                Deployment Name should be in accordance with the standards
              </FormFeedback>
            </FormGroup>
          </div>
          <div className="innerRow flexStart mb">
            <p className="p">Image Name: {location.state?.imageName} </p>
          </div>
          <div className="innerRow flexStart mb">
            <p className="p">Tag Name: {location.state?.tag} </p>
          </div>
        </Col>
        <Col xs="12">
          <div
            style={{
              maxHeight: "35vh",
              backgroundColor: "#171717",
              borderRadius: "15px",
              overflowY: "auto",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingBottom: "10px",
              }}
            >
              <h6>ENVs</h6>
              <div
                className="add-icon"
                onClick={() =>
                  setEnvVariables((prevEnv) => [
                    ...prevEnv,
                    {
                      name: "",
                      value: "",
                    },
                  ])
                }
              >
                +
              </div>
            </div>
            {envVariables.map(({ name, value }, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "30px",
                    marginBottom: "10px",
                  }}
                  key={index}
                >
                  <h6 style={{ marginRight: "10px" }}>{index + 1}</h6>
                  <Input
                    type="text"
                    value={name}
                    placeholder="ENV Variable"
                    style={{
                      backgroundColor: "#505050",
                      color: "white",
                      marginRight: "10px",
                    }}
                    onChange={(e) => {
                      const updatedEnvVariables = [...envVariables];
                      updatedEnvVariables[index].name = e.target.value;
                      setEnvVariables(updatedEnvVariables);
                    }}
                  />
                  <Input
                    type="text"
                    value={value}
                    placeholder="ENV Variable Value"
                    style={{
                      backgroundColor: "#505050",
                      color: "white",
                      marginRight: "10px",
                    }}
                    onChange={(e) => {
                      const updatedEnvVariables = [...envVariables];
                      updatedEnvVariables[index].value = e.target.value;
                      setEnvVariables(updatedEnvVariables);
                    }}
                  />
                  <div
                    className="delete-icon"
                    style={{ padding: "10px" }}
                    onClick={() =>
                      setEnvVariables((prevEnv) =>
                        prevEnv.filter((_, i) => i !== index)
                      )
                    }
                  >
                    x
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
      <Row noGutters>
        <Col
          xs={12}
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            type="button"
            className={
              isBeingDeployed ||
              !deploymentNameValidity ||
              (envVariables.length &&
                envVariables.some(
                  (variable) => !variable.name || !variable.value
                ))
                ? "btn btn-primary codeEditorCommitButtonDisabled"
                : "btn btn-primary codeEditorCommitButton"
            }
            style={{ marginRight: 40 }}
            onClick={handleDeploy}
            disabled={
              isBeingDeployed ||
              !deploymentNameValidity ||
              (envVariables.length &&
                envVariables.some(
                  (variable) => !variable.name || !variable.value
                ))
            }
          >
            {isBeingDeployed ? "Updating..." : "Update Configuration"}
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default DeploymentConfig;
