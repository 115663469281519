import { useContext, useState } from "react";
import DemoIcon from "../../../src/assets/images/demo.jpeg";
import SettingsIcon from "../../../src/assets/images/Settings.svg";
import { categoriesArray } from "./WorkSpaceConnectorsUtils";
import { ConnectorsContext } from "./WorkSpaceConnectorsMainPage";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
const availableNames = [
  "Kinesis",
  "Google Cloud Pub/Sub",
  "Event Hub",
  "Amazon SQS",
  "Threshold",
  "BIGTABLE"
];

const WorkSpaceConnectorsCard = (props) => {
  const {
    id,
    name,
    type,
    description,
    isComingSoon,
    connectionType,
    device_name,
    manufact_name,
  } = props.data;

  const [redirectToEditor, setRedirectToEditor] = useState(false);
  const { handleAddPipeLineClicked, propsLabel } =
    useContext(ConnectorsContext);
  let category = JSON.parse(connectionType);

  const getBackgroundColor = () => {
    // const category = connectionType.find((cat) => cat.category === categoryName);
    if (category) {
      switch (category.category) {
        case "input":
          return "yellow-gradient";
        case "transforms":
          return "pink-gradient";
        case "output":
          return "orange-gradient";
        default:
          return "";
      }
    }
    return "";
  };

  if (redirectToEditor)
    return (
      <Redirect
        to={{
          pathname: "/editor",
          state: {
            isRedirected: true,
          },
        }}
      />
    );

  const isNameAvailable = availableNames.includes(name || device_name);
  const isDefaultValue = getBackgroundColor() === "";

  return (
    <div className="wsConnectorCardContainer" key={id}>
      <div className={`wsConnectorCardLeftLine ${getBackgroundColor()}`}></div>
      <div className="wsConnectorCardContentArea">
        <div className="wsConnectorCardFirstRow">
          <div className="wsConnectorCardLogo">
            <img src={DemoIcon} alt="" />
          </div>
          <div className="wsConnectorCardTitleArea">
            <div className="wsConnectorCardTitleName">
              <p>{name ? name : device_name}</p>
            </div>
            <div className="wsConnectorCardTitleType">
              <p>{type ? type : category && category.category.toUpperCase()}</p>
            </div>
          </div>
        </div>
        <div className="wsConnectorCardDescription">
          <p>
            {description
              ? description
              : `${manufact_name.toUpperCase()} ${device_name} Input Connector`}
          </p>
        </div>
        {isComingSoon || (!isDefaultValue && !isNameAvailable) ? (
          <div className="wsConnectorCardDisabledBox">
            <p>Coming Soon</p>
          </div>
        ) : (
          <div
            className="wsConnectorCardButtonBox"
            onClick={() => {
              if (type === "TRANSFORMS") setRedirectToEditor(true);
              else
                handleAddPipeLineClicked(
                  name ? name : device_name,
                  type ? type : "INPUT"
                );
            }}
          >
            <div className="wsConnectorCardButtonLogo">
              <img src={SettingsIcon} alt="" />
            </div>
            <div className="wsConnectorCardButtonText">
              <p>Add To Pipeline</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkSpaceConnectorsCard;
