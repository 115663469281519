import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { TourProvider } from "@reactour/tour";
import { workspace } from "./steps";
import CustomTourCard from "./customWalkthrough";
import { AuthProvider } from "./AuthContext";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    {/* <TourProvider
      showDots="false"
      prevButton={({ currentStep, setCurrentStep, steps }) => {
        const first = currentStep === 0;
        return (
          <button
            className="walkThroughButton"
            onClick={() => {
              if (first) {
                setCurrentStep((s) => steps.length - 1);
              } else {
                setCurrentStep((s) => s - 1);
              }
            }}
          >
            Back
          </button>
        );
      }}
      nextButton={({
        currentStep,
        stepsLength,
        setIsOpen,
        setCurrentStep,
        steps,
        start,
      }) => {
        const last = currentStep === stepsLength - 1;
        return (
          <div>
            <button
              className="walkThroughNext"
              onClick={() => {
                if (last) {
                  setIsOpen(false);
                  setCurrentStep(0);
                } else {
                  setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1));
                }
              }}
            >
              {last ? (
                "Close!"
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="10"
                  viewBox="0 0 29 8"
                  fill="none"
                >
                  <path
                    d="M28.3536 4.35355C28.5488 4.15829 28.5488 3.84171 28.3536 3.64645L25.1716 0.464466C24.9763 0.269204 24.6597 0.269204 24.4645 0.464466C24.2692 0.659728 24.2692 0.976311 24.4645 1.17157L27.2929 4L24.4645 6.82843C24.2692 7.02369 24.2692 7.34027 24.4645 7.53553C24.6597 7.7308 24.9763 7.7308 25.1716 7.53553L28.3536 4.35355ZM0 4.5H28V3.5H0V4.5Z"
                    fill="#081C15"
                  />
                </svg>
              )}
            </button>
          </div>
        );
      }}
      styles={{
        popover: (base) => ({
          ...base,
          display: "flex",
          width: "400px",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "12px",
          background: "var(--base-white, #FFF)",
          boxShadow:
            "0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10)",
        }),

        // badge: (base) => console.log("Tjos os dsd", base),
      }}
    > */}
    <AuthProvider>
      <App />
      </AuthProvider>
    {/* </TourProvider> */}
  </React.StrictMode>
);
