import React, { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import NavBar from "../components/NavBar/NavBar";
import jwtDecode from "jwt-decode";
import { SnackbarProvider } from "../layouts/Context/snackBarContext";
import TopSnackBar from "../components/common/_topSnackBar";
import { useAuth } from "../AuthContext";

/**
 *
 * @description (AppRoute - Defines Page Routes)
 * @param {*} { component: Component, isAuthProtected, ...rest }
 */
const /**
   *
   *
   * @param {*} props
   * @return {*}
   */
  AppRoute = ({ component: Component, isAuthProtected, ...rest }) => {
    const { isAuthenticated, logout } = useAuth();
    let _automicExpireTime = null;
        let _currentTime = Math.floor(Date.now() / 1000);
        let _token = localStorage.getItem("token");
        if (_token) {
         _automicExpireTime = jwtDecode(_token).exp;
        }else if(!_token && isAuthProtected){
          return(
            <Redirect to={{pathname:"/"}}></Redirect>        
          )
        }

        if (
          isAuthProtected &&
          _automicExpireTime &&
          _currentTime > _automicExpireTime
        ) {
          logout();
          return <Redirect to={{ pathname: "/" }} />;
        } else if (
          !isAuthProtected &&
          _automicExpireTime &&
          _currentTime < _automicExpireTime
        ) {
          return <Redirect to={{ pathname: "/onBoarding" }} />;
        }
    return(
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected) {
          return (
            <SnackbarProvider>
              <NavBar />
              <TopSnackBar />
              <Component {...props} />
            </SnackbarProvider>
          );
        } else {
          return (
            <SnackbarProvider>
              <TopSnackBar />
              <Component {...props} />
            </SnackbarProvider>
          );
        }
      }}
    />
    )
    } 

export default AppRoute;
