import { useState, useContext } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Typography from "../../components/common/_typography";
import InputField from "../../components/common/_loginInputField";
import Button from "../../components/common/_button";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";

/**
 * NOTE: Structured Component
 * @description This Component is for validating OTP entered during Forget Password flow.
 * @return {*}
 */
const ForgetPasswordOtpValidation = (props) => {
  let userDetails = props.userName;
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);

  const [otp, setOtp] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(true);

  const handleOtpInput = (event) => {
    setOtp(event.target.value);
  };

  const checkOtpLength = () => {
    const otpRegex = new RegExp(/^[0-9]{6,6}$/);

    if (otpRegex.test(otp) == false) {
      setIsOtpValid(false);
      showMessage("Please enter valid OTP", "success");
      return false;
    } else {
      return true;
    }
  };

  const handleSubmitClick = async () => {
    setIsOtpValid(true);

    //Checking OTP length
    let checkOtpResponse = await checkOtpLength();

    //If OTP length is correct, calling OTP validation API
    if (checkOtpResponse == true) {
      history.push({
        pathname: "/resetPassword",
        otp: otp,
        userName: userDetails,
      });
    } else {
      setIsOtpValid(false);
    }
  };

  const handleResendOtpClick = async () => {
    showMessage("OTP is being sent again...", "success");

    try {
      let stringifiedData = JSON.stringify({
        clientId: env.REACT_APP_CLIENT_ID,
      });
      //API Call: Re-Sending OTP to registered email-Id for Forget Password.
      let sendOtpResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "admin/generateOtp",
        stringifiedData
      );

      if (sendOtpResponse.data.code == 200) {
        showMessage("Successfully resent OTP.", "success");
      }
    } catch (error) {
      showMessage("Failed to resend OTP.");
    }
  };

  return (
    <>
      <Col xs={12} className="landingTitle">
        <Typography text={props.title} color={" #FAFAFA;"} tag={"head-small"} />
      </Col>
      <Col xs={12} className="landingDescription">
        <Col>
          <Typography
            text={props.description}
            color={" #B7B7B7;"}
            tag={"head-x-x-small"}
          />
        </Col>
      </Col>
      <Col xs={12} className="inputTextStyleOtp">
        <InputField
          throwError={!isOtpValid}
          placeholder={props.placeholder}
          type={props.inputType}
          value={otp}
          onChange={handleOtpInput}
        />
      </Col>

      <Col xs={12} className="resendOtpStyle" onClick={handleResendOtpClick}>
        <Col>
          <Typography
            text={[
              "Didn’t receive an OTP? ",
              <b>
                <a>
                  <u>Resend OTP</u>
                </a>
              </b>,
            ]}
            color={" #FAFAFA;"}
            tag={"p-large"}
          />
        </Col>
      </Col>
      <Col xs={12} className="otpButtonMargin">
        <Button
          disabled={otp == ""}
          tag={"large-primary"}
          btnText={"Submit"}
          onClickEvent={handleSubmitClick}
        />
      </Col>
      <Col xs={12} className="otpCancelStyle">
        <Typography
          tag={"head-x-x-small"}
          color={" #FAFAFA;"}
          text={[
            <a href="/login">
              <u>Login</u>
            </a>,
          ]}
        ></Typography>
      </Col>
    </>
  );
};

export default withRouter(ForgetPasswordOtpValidation);
