import "../../scss/components/buttonAvatar/buttonAvatar.scss";

function ButtonAvatar({ src, alt }) {
  return (
    <div className="avatar">
      <img src={src} alt={alt} />
    </div>
  );
}

export default ButtonAvatar;
